
import Layout from '../../components/shared/layout/layout'

function index() {
  return (
    <>
        <Layout>
            {/* Develop a contcat us page for me here */}
            

        </Layout>
    </>
  )
}

export default index