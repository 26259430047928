import { zTeenImg } from "../../assets";
import Button from "../../components/button/button";
import Layout from "../../components/shared/layout/layout";
import MonthSuperstar from "./components/month-superstar";
import ReadyToJoin from "./components/ready-to-join";
import StudentLedProject from "./components/student-led-projects";
import UpcomingEvents from "./components/upcoming-event";
import WhatsIsZTeens from "./components/whatsIsTeen";
import WhyZTeens from "./components/why-z-teens";
import WhatTeensCard from "./components/what-teens-card";
import { webDev } from "../../assets";
import teens4 from "../../assets/videos/teens4.mp4";

const ZTEENS = () => {
  return (
    <>
      <Layout>
        <WhatsIsZTeens />
        <div className="max-w-[77.375rem] py-3 bg-[#cf375d] mx-auto rounded-[10px]">
          <div className="flex xs:block sm:block md:block items-center gap-3 text-white  ">
            <div className="xs:hidden sm:hidden md:hidden">
              <img src={zTeenImg} alt="img" />
            </div>
            <div className="xs:px-2 sm:px-2 md:px-2">
              <h1 className="text-[32px] xs:text-[20px] sm:text-[25px]  ruda font-[700] max-w-[34rem]">
                Teens Club: Empowering the Next Generation of Tech Innovators
              </h1>
              <h1 className="text-[24px] py-3 font-[400] xs:text-[16px] sm:px-[18px]  khula">
                Bringing Software development to Secondary Schools{" "}
              </h1>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfUuvrbrRAHm-fp1O0cJlnlSO21akZDgR7Kt3i1piufsdJi_g/viewform"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  btnText={"Get Started"}
                  btnClassName={
                    "py-[.5rem] mt-[1.5rem] xs:w-fit sm:w-fit md:w-fit xs:px-[1rem] sm:px-[1rem] xs:text-[14px]"
                  }
                />
              </a>
              {/* <br /><h1>Sign your school up for a tryout session <a className="text-[black] underline" href="" rel="noreferrer">here</a></h1> */}
            </div>
          </div>
        </div>

        <WhyZTeens />
        <div className="max-w-[77.375rem] items-center flex gap-4 xs:block sm:block md:block justify-between px-[3rem] py-[3rem] rounded-[10px] mx-auto my-[3rem] bg-[#fff]">
          <div className="  max-w-[19rem] md:mx-auto sm:mx-auto xs:mx-auto my-5  text-white ">
            <video className="w-full" controls>
              <source src={teens4} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className=" flex xs:flex-col sm:flex-col md:flex-col max-w-[50.625rem] overflow-x-auto no-scrollbar xs:pt-2 sm:pt-2 md:pt-2 gap-10 items-center">
            <WhatTeensCard
              title={"Workshops"}
              description={
                "Engaging hands-on workshops on software development, mobile app development, robotics, and other tech-related subjects, tailored to the age and experience level of secondary school students."
              }
            />
            <WhatTeensCard
              title={"Team Projects"}
              description={
                "Engaging collaborative projects that inspire creativity and critical thinking, enabling students to apply their knowledge in practical, real-world scenarios and develop their problem-solving skills."
              }
            />
            <WhatTeensCard
              title={"Internship Offers"}
              description={
                "During students’ holiday, we are excited at Zulfah to have them visit our office for short internship offers where they collaborate with our in-house professional engineers, developers, and designers."
              }
            />
          </div>
        </div>
        <StudentLedProject />
        <UpcomingEvents />
        <MonthSuperstar />
        <ReadyToJoin />
      </Layout>
    </>
  );
};

export default ZTEENS;
