import { dashboardVid } from "../../../assets";
import zee from "../../../assets/zee2.png";

const DashboardVideo = ()=>{
    return (
      <>
        <div className="max-w-[77.5rem]  sm:h-fit xs:h-fit md:h-fit h-[rem] mx-auto my-[4rem]">
            <img src={zee} alt="" />
        </div>
      </>
    );
};

export default DashboardVideo;