import { monitorInYellowCircle, personInYellowCircle, unleashCreativityIcon, whyZTeensImg } from "../../../assets";

const WhyZTeens = () => {
  return (
    <>
      <div className="mx-auto max-w-[77.375rem] my-[4rem]">
        <div className="flex xs:block gap-2 sm:block md:block items-center justify-between">
          <div>
            <div className="max-w-[26.313rem] xs:mx-auto xs:text-center sm:mx-auto sm:text-center md:text-center md:mx-auto">
              <h1 className="text-[32px] font-[900] xs:mx-auto sm:mx-auto md:mx-auto ruda">
                Why Z Teens?
              </h1>
              <p className="text-[20px] font-[400] py-[1rem] ">
                Discover the unique benefits that make Z Teens the perfect place
                to learn, grow, and thrive in tech.
              </p>
            </div>
            <div>
              <div className="flex gap-4 items-center ">
                <img
                  src={monitorInYellowCircle}
                  className="xs:py-2 sm:py-2 md:py-2 "
                  alt=""
                />
                <div className=" text-[24px]">
                  <h1 className="font-[600] py-[1rem] xs:text-[18px] sm:text-[22px]">
                    Interactive Learning
                  </h1>
                  <p className=" font-[300] xs:text-[16px] sm:text-[20px]">
                    Students Learn by doing with fun, project-based activities.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="flex gap-4 items-center">
                <img src={personInYellowCircle} alt="" />
                <div className=" text-[24px]">
                  <h1 className="font-[600] py-[1rem] xs:text-[18px] sm:text-[22px]">
                    Tech Mentors
                  </h1>
                  <p className=" font-[300] xs:text-[16px] sm:text-[20px]">
                    Students get Get exclusive guidance and inspiration from
                    tech professionals.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="flex gap-4 items-center">
                <img src={unleashCreativityIcon} alt="" />
                <div className=" text-[24px]">
                  <h1 className="font-[600] py-[1rem] xs:text-[18px] sm:text-[22px]">
                    Unleash Creativity
                  </h1>
                  <p className=" font-[300] xs:text-[16px] sm:text-[20px]">
                    Student express themselves and solve real problems in unique
                    ways.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="xs:py-5 sm:py-5 md:py-5 xs:mx-auto xs:w-fit sm:mx-auto sm:w-fit md:mx-auto md:w-fit">
            <img src={whyZTeensImg} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyZTeens;
