import { resourcePageFirstImg } from "../../assets";
import Layout from "../../components/shared/layout/layout";
import LatestNews from "./component/latest-news";
// import marketOutreach from "../../assets/videos/market-outreach.mp4";

const Resources = () => {
  return (
    <>
      <Layout>
        <br />
        <br />
        <div className="max-w-[75.75rem] flex xs:block sm:block md:block lg:block mx-auto gap-4">
          <div className=" xs:w-fit sm:w-fit md:w-fit lg:w-fit xs:mx-auto sm:mx-auto lg:mx-auto xs:my-4 sm:my-4 md:my-4 lg:my-4  md:mx-auto">
            {/* <img src={resourcePageFirstImg} alt="" /> */}
            {/* <video  controls autoplay muted loop>
                <source
                  src="https://www.youtube.com/watch?v=-J1gJw9ffGE"
                  type="video/mp4"
                />
              </video> */}
            <iframe
              width="360"
              // className="sm:w-160 xs:w-160 md:w-560 lg:w-560 !xl:w-560"
              
              height="315"
              src="https://www.youtube.com/embed/OM72DSHXj5s"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen="true"
              autoPlay="true"
            ></iframe>
            {/* <video controls autostart autoPlay style={{
                width : "560px", height : "315px"
               }} src={marketOutreach} type="video/mp4" className="bg-[#000]" /> */}
          </div>
          <div className="max-w-[auto]  xs:mx-auto sm:mx-auto md:mx-auto lg:mx-auto ">
            {/* <div className="flex items-center gap-3">
                <div className="w-[2.101rem] h-[2.101rem] bg-[#d9d9d9] rounded-full"></div>
                <h1 className="text-[10.96px] ruda">TVC News</h1>
                <div className="w-[4px]  h-[4px] rounded-full bg-[#d9d9d9]"></div>
                <p className="text-[10.08px] text-[#d9d9d9]">2 Minutes Watch</p>
              </div> */}
            <p className="text-[17.08px] xs:text-center sm:text-center md:text-center lg:text-center font-[400]">
              Recently, we had the privilege of hosting an unforgettable event
              that brought together an incredible group of passionate
              individuals, all eager to make their mark in the world of
              technology. From beginners with no prior experience to those
              already exploring the tech landscape, their determination,
              curiosity, and energy left us in awe. What stood out the most was
              the hunger to learn, grow, and build something
              meaningful—qualities that are the foundation of success in the
              tech industry. As featured by TVC News, this event proved one
              powerful truth: tech is not just for the elite or the highly
              experienced. It’s for anyone with the drive to dive in, learn, and
              innovate. The diverse range of participants showed us that the
              future of tech is not just about coding or algorithms; it’s about
              passion, creativity, and collaboration. There is a place for
              everyone in this ever-evolving space.
            </p>
            <br />
            <a
              href="https://www.linkedin.com/feed/update/urn:li:activity:7261486531712835584"
              target="_blank"
              rel="noreferrer"
            >
              <h1 className="text-[16.3px] ruda font-[900] text-blue-500">
                continue reading ...
              </h1>
            </a>
          </div>
        </div>
        <LatestNews />
      </Layout>
    </>
  );
};

export default Resources;
