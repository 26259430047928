import {
  countDown,
  deliveryIcon,
  gasContainerIcon,
  refillIcon,
  setReminderIcon,
  stayConnectedImg,
} from "../../assets";
import Button from "../../components/button/button";
import Layout from "../../components/shared/layout/layout";
import StayConnected from "../dashboard/component/stay-connected";
import GasLitPoints from "./components/gas-lit-points";
import ProductCard from "./components/product-card";
import gasleetLogo from "../../assets/gasleet.png";

const Projects = () => {
  return (
    <>
      <Layout>
        <div className="max-w-[46.313rem] mx-auto">
          <h1 className="text-[48px] ruda font-[900] text-center">
            Our Innovative Tech Solutions
          </h1>
          <p className="text-[20px] font-[400] text-center my-[1.5rem]">
            Explore a wide range of products designed to empower businesses and
            individuals with the latest in technology.
          </p>
        </div>
        <div className="max-w-[75.313rem] mx-auto">
          <h1 className="text-[32px] xs:text-[16px] sm:text-[18px] md:text-[20px] font-[800]  xs:text-center sm:text-center md:text-center ruda my-[2rem]">
            Products Spotlight
          </h1>
          <div className="flex gap-4 xs:block sm:block md:block xs:mx-auto sm:mx-auto md:mx-auto justify-between">
            <div className="w-[36.938rem] xs:max-w-[15rem] sm:max-w-[25rem] md:max-w-[36.938rem] rounded-[10px] bg-[#eCb32cdb]   sm:text-[16px] md:text-[18px] xs:mx-auto sm:mx-auto md:mx-auto ">
              <div className="flex justify-between items-center">
                <img src={gasleetLogo} alt="img" className="w-full h-full " />
              </div>
            </div>
            <div className="max-w-[35.75rem] xs:mx-auto sm:mx-auto md:mx-auto">
              <h1 className="text-[32px] font-[800]  xs:text-center sm:text-center md:text-center ruda">
                Gasleet
              </h1>
              <p className="text-[23px] xs:text-[18px] sm:text-[18px] font-[400] leading-[37px]  xs:text-center sm:text-center md:text-center">
                Gasleet is a smart gas monitoring solution that helps you track
                your gas consumption and get alerts when it’s time for a refill.
                The Gasleet app also allows you to monitor your gas usage over
                time and set custom reminders for refills.
              </p>
              <GasLitPoints
                img={gasContainerIcon}
                title={"Real-Time Gas Monitoring"}
                description={
                  "Track your gas consumption and get alerts when it’s time for a refill."
                }
              />
              <GasLitPoints
                img={deliveryIcon}
                title={"Free Gas Cylinder Delivery"}
                description={
                  "No more trips to the store; we deliver your gas right to your door."
                }
              />
              <GasLitPoints
                img={refillIcon}
                title={"Instant Refill Notifications"}
                description={
                  "Get notified when it’s time for a refill, so you’re always prepared."
                }
              />
              <GasLitPoints
                img={setReminderIcon}
                title={"Track Usage and Set Reminders"}
                description={
                  "Monitor your gas usage over time and set custom reminders for refills."
                }
              />

              <a href="https://gasleet.com" target="_blank" rel="noreferrer">
                <Button
                  btnText={"Discover Now"}
                  btnClassName={
                    "!bg-[#cf375d] px-[1rem] !w-fit !h-fit xs:text-[12px] sm:text-[16px]"
                  }
                />
              </a>
            </div>
          </div>
        </div>

        <div className="max-w-[75.313rem] sm:mx-auto xs:text-[14px] sm:text-[16px] md:text-[18px] mx-auto">
          <h1 className="ruda text-[32px] font-[800] xs:text-center sm:center md:text-center my-[1.5rem]">
            Products
          </h1>
          <div className="flex xs:block sm:block md:block sm:mx-auto xs:mx-auto md:mx-auto gap-[2rem]">
            <ProductCard
              title={"Farmeely - Shared Grocery Shopping"}
              description={
                "Farmeely is a shared grocery shopping platform that allows you to shop for groceries with friends and family."
              }
              link="https://farmeely.com"
            />

            <ProductCard
              title={"xwapIt"}
              description={
                "The digital trade by barter system that allows you to exchange goods and services with others."
              }
              link="https://xwapit.com"
            />

            <ProductCard
              title={"zcoop"}
              description={
                "A Digital Cooperative Society Platform that helps you manage your cooperative society with ease."
              }
              link="https://zulfahacademy.com"
            />
          </div>
        </div>
        <div className="my-[4rem]">
          <div className="max-w-[77.375rem] mx-auto py-4 px-4 rounded-[10px] text-white bg-[#59b5cd]">
            <div className="flex justify-between items-center">
              <div className=" mx-auto">
                <h1 className="text-[36px] xs:text-[20px] sm:text-[26px] font-[700]">
                  Join Our Academy Community
                </h1>

                <p className="max-w-[31.313rem] xs:text-[16px] sm:text-[20px]  py-[2rem] text-[20px]">
                  Stay ahead with our newsletter’s exclusive resources, updates,
                  and insights. Sign up for the latest Academy offerings and
                  tips.
                </p>

                <div className="flex gap-1  xs:block sm:block md:block justify-between">
                  <input
                    className="bg-white py-[1rem] xs:w-full sm:w-full text-black outline-none px-[1rem] w-[20rem] opacity-[37%] rounded-[10px]"
                    type="text"
                    placeholder="Enter Your Email"
                  />
                  <Button
                    btnText={"Subscribe Now"}
                    btnClassName={"xs:mt-[1rem] sm:mt-[1rem] md:mt-[1rem]"}
                  />
                </div>
              </div>
              <div>
                <img
                  className="rounded-[10px] xs:hidden sm:hidden md:hidden"
                  src={stayConnectedImg}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Projects;
