const ProductCard = ({title,description, img=null, link}) =>{
    return (
      <>
        <div>
          <div className="bg-[#ecb32c]  max-w-[29.188rem] xs:mx-auto sm:mx-auto md:mx-auto h-[24.25rem] rounded-[10px]">
            {
              img === null ? ('') : <img src={img} alt="img" />
            }
          </div>

          <div className="xs:mx-auto sm:mx-auto md:mx-auto xs:text-center sm:text-center md:text-center">
            <h1 className="text-[25px] my-[1.5rem]  font-[800] ruda">
              {title}
            </h1>
            <p className="text-[22px] xs:mx-auto sm:mx-auto md:mx-auto font-[400] my-[1.5rem] max-w-[29rem] leading-[35.32px]">
              {description}
            </p>
            <h1 className="text-[#ecb32c] my-[1.5rem] font-[400]">
              <a href={link} target="_blank" rel="noreferrer">Discover Now</a>
            </h1>
          </div>
        </div>
      </>
    );
};

export default ProductCard