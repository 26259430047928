import { NavLink, useNavigate } from "react-router-dom";
import Button from "../../../components/button/button";

const CourseCard = ({
  courseImg,
  courseType,
  courseDuration,
  courseDescription,
  numberOfCourse1,
  numberOfCourse2,
  courseWatchTime,
  btnText,
  handleClick,
  to,
}) => {
  

  return (
    <>
      <NavLink to={to}>
      <div className="relative max-w-[37.475rem] xs:my-[1rem] md:my-[1rem] sm:my-[1rem] border border-[#d9d9d9] rounded-[11.65px] py-[2.5rem] px-[2.5rem] bg-[#f8f8f8] hover:bg-[#59b5cd] hover:text-[#fff]">
        <div className="max-w-[34.553rem] mx-auto ">
          <img src={courseImg} alt="" />
        </div>
        <div className="flex items-center gap-2 justify-between py-[1rem]">
          <h1 className="text-[22px] xs:text-[16px] sm:text-[18px] lg:text-[16px] font-[700]">
            {courseType}
          </h1>
          <h1 className="text-[19.25px] xs:text-[15px] sm:text-[15px] md:text-[15px] lg:text-[15px] font-[600]">
            {courseDuration}
          </h1>
        </div>
        <p className="text-[17.88px] xs:text-[14px] sm:text-[14px] py-[1rem]">
          {courseDescription}
        </p>
        {/* <hr className="border border-[#d9d9d9] my-[1.5rem]" /> */}
        {/* <div className="flex items-center py-[1rem] justify-between xs:text-[16px] sm:text-[16px] text-[22px] lg:text-[16px] font-[700]">
          <h1>{numberOfCourse1}</h1>
          <h1>{numberOfCourse2}</h1>
          <h1>{courseWatchTime}</h1>
        </div> */}


        <Button
          onClick={handleClick}
          btnText={btnText}
          btnClassName={
            "absolute top-10 right-10 !h-fit !w-fit px-[1.5rem] !py-[.5rem] !text-[15.14px]"
          }
         
        />
      </div>
      </NavLink>
    </>
  );
};

export default CourseCard;
