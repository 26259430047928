import { Link } from "react-router-dom";
import { softwareEngPageFirstImg } from "../../assets";
import Button from "../../components/button/button";
import Card from "../../components/card";
import SecondCard from "../../components/courses-second-card";
import Layout from "../../components/shared/layout/layout";
import CourseDescription from "./component/course-description";

const SoftwareEngineering = () => {
  return (
    <>
      <Layout>
        <div className="max-w-[77.375rem] bg-[#59b5cd] mx-auto rounded-[10px]">
          <div className="flex  items-center justify-between  xs:py-2 ">
            <div className="pl-[2rem] text-white ">
              <h1 className="text-[36px] xs:text-[20px] sm:text-[25px] font-[700] ">
                SOFTWARE ENGINEERING
              </h1>
              <Link to={"/get-started"}>
                <Button
                  btnText={"Beginner Friendly"}
                  btnClassName={
                    "!text-[18px] xs:text-[12px] sm:text-[14px] !bg-[#ecb32c] mt-[2rem] "
                  }
                />
              </Link>
            </div>
            <div className="xs:hidden ">
              <img src={softwareEngPageFirstImg} alt="img" />
            </div>
          </div>
        </div>

        <div className="max-w-[77.375rem] mx-auto">
          {/* <div className=" w-fit lg:hidden xl:hidden xs:mx-auto sm:mx-auto md:mx-auto ">
            <Card />
          </div> */}
          <div className="flex xs:items-center sm:items-center md:items-center xs:flex-col sm:flex-col md:flex-col gap-4  ">
            <div>
              <CourseDescription />
              {/* <div className="my-[1rem] w-fit lg:hidden xs:mx-auto sm:mx-auto md:mx-auto">
                <SecondCard />
              </div> */}
            </div>
            <div className="xs:items-center sm:items-center md:items-center">
              <Card />
              <SecondCard />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SoftwareEngineering;
