import { BrowserRouter, Route, Routes } from "react-router-dom";
import { pageRoutes } from "./routes";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
 
      <BrowserRouter>
        <Routes>
          {pageRoutes.map((eachPage, i) => (
            <Route
              key={i}
              path={eachPage.path}
              element={<eachPage.element />}
            />
          ))}
        </Routes>
      </BrowserRouter>
      <ToastContainer
          position="top-right"
          autoClose={5000}
          theme="dark"
          transition={Bounce}
      />
    </>
  );
}

export default App;
