import { yellowArrowLeft, yellowArrowRight } from "../../../assets";
import Button from "../../../components/button/button";
import ResourceCard from "./resource-card";
import marketOutreach from "../../../assets/videos/market-outreach.mp4";
import ariseTv from "../../../assets/videos/arisetv.mp4";
import zee from "../../../assets/zee.jpg";
import techFit from "../../../assets/techFit.jpeg";

const LatestNews = () =>{
    return (
      <>
        <div className="max-w-[75.75rem] mx-auto">
          <br />
          <br />
          <div>
            <h1 className="text-[32px] ruda font-[900]">In the News</h1>
          </div>
          <div>
            <div>
              <div className="flex flex-wrap gap-2 xs:block sm:block md:block xs:w-fit sm:w-fit md:w-fit xs:mx-auto sm:mx-auto md:mx-auto justify-between my-[2rem] xs:space-y-4 sm:space-y-4 md:space-y-4">
                <ResourceCard
                  title={"AriseTv News"}
                  pText={
                    "We believe extraordinary journeys often start with simple steps. At our #HatchIt event, aired by AriseTv News, we met aspiring tech enthusiasts whose stories show that with the right guidance and tools, even the smallest beginnings can lead to world-changing innovations."
                  }
                  pText2={
                    " During its third-anniversary programme, Hatch It, in Lagos, the academy brought together industry experts who discussed the growing demand for Nigerian tech talents globally."
                  }
                  aLink={"https://www.linkedin.com/company/zulfah-group/"}
                  aText={"Continue reading ..."}
                  video={ariseTv}
                  date={"Novmber 13, 2024"}
                  watchTime={"3 Minutes watch"}
                />
                <ResourceCard
                  title={"Zulfah News"}
                  img={
                    "https://cdn.vanguardngr.com/wp-content/uploads/2024/11/b9059630-e1c2-4029-bd71-9b90e0c2d4c6.jpeg"
                  }
                  pText={
                    "We believe greatness often begins in the most humble places. Recently, during our market outreach in Sabo Market, Ikorodu. We met incredible people whose stories reflect that resilience and dreams can flourish from small beginnings."
                  }
                  pText2={
                    " The Chief Executive Officer of Zulfah Academy, Mr Abayomi Ajao, said this at a Tech-Fit event organised as part of the programmes to mark the third anniversary of the academy in Lagos during the weekend."
                  }
                  aLink={
                    "https://www.linkedin.com/feed/update/urn:li:activity:7259493401237090305"
                  }
                  aText={"Continue reading ..."}
                  video={marketOutreach}
                  date={"Novmber 13, 2024"}
                  watchTime={"3 Minutes watch"}
                />
                <ResourceCard
                  title={"TVC News"}
                  pText={
                    "At TechFit, we believe in the power of transformation. Recently, we had the privilege of connecting with bright, determined individuals ready to break into the tech world. From absolute beginners to those already exploring tech, their passion and hunger to learn left us in awe. It’s moments like these that remind us—tech isn’t just for the chosen few; it’s for everyone who’s willing to put in the work and embrace the future. Get ready to witness greatness in the making!"
                  }
                  pText2={
                    " During its third-anniversary programme, Hatch It, in Lagos, the academy brought together industry experts who discussed the growing demand for Nigerian tech talents globally."
                  }
                  aLink={"https://www.youtube.com/embed/OM72DSHXj5s"}
                  aText={"Continue reading ..."}
                  video={"https://www.youtube.com/embed/OM72DSHXj5s"}
                  date={"Novmber 13, 2024"}
                  watchTime={"3 Minutes watch"}
                />
              </div>
              <div className="flex flex-wrap gap-2 xs:block sm:block md:block xs:w-fit sm:w-fit md:w-fit xs:mx-auto sm:mx-auto md:mx-auto justify-between my-[2rem]">
                <ResourceCard
                  title={"Vangaurd Newspaper"}
                  img={zee }
                  pText={
                    "Zulfah Academy, a Nigerian tech academy, has given reasons why  many information technology experts are considered rich in Nigeria."
                  }
                  
                  aLink={
                    "https://www.vanguardngr.com/2024/11/why-people-in-tech-are-rich-zulfah/"
                  }
                  aText={"Read more ..."}
                />
                <ResourceCard
                  title={"Punch Newspaper"}
                  img={techFit}
                  pText={
                    "Zulfah Academy, a Nigerian tech training institution, has urged tech professionals to reconsider the trend of relocating abroad, commonly referred to as “japa,” and instead focus on leveraging the numerous opportunities available within the country."
                  }
                  
                  aLink={
                    "https://punchng.com/firm-canvasses-tech-skills-development/#google_vignette"
                  }
                  aText={"Read more ..."}
                  date={"Novmber 8, 2024"}
                  watchTime={"5 Minutes Read"}
                />
                <ResourceCard
                  title={"Vangaurd Newspaper"}
                  img={zee }
                  pText={
                    "Zulfah Academy, a Nigerian tech academy, has given reasons why  many information technology experts are considered rich in Nigeria."
                  }
                  aLink={
                    "https://www.vanguardngr.com/2024/11/why-people-in-tech-are-rich-zulfah/"
                  }
                  aText={"Read more ..."}
                /> 
              </div>
              <div className="flex flex-wrap gap-2 xs:block sm:block md:block xs:w-fit sm:w-fit md:w-fit xs:mx-auto sm:mx-auto md:mx-auto justify-between mt-[3rem] my-[2rem]"></div>
            </div>
          </div>
          {/* <div className="flex  items-center justify-between my-[2rem]">
            <Button
              imgBef={yellowArrowLeft}
              btnClassName={
                "!bg-white border-[1px] border-[#ecb32c] font-[700] border-[2px] !w-fit !px-[1rem] !text-[#ecb32c]"
              }
              btnText={"Previous"}
            />
            <Button
              imgAft={yellowArrowRight}
              btnClassName={
                "!bg-white border-[1px] border-[#ecb32c] font-[700] border-[2px] !w-fit !px-[1rem] !text-[#ecb32c]"
              }
              btnText={"Next"}
            />
          </div> */}
        </div>
        <br />
        <br />
      </>
    );
};

export default LatestNews;
