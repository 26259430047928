import { stayConnectedImg } from "../../../assets";
import Button from "../../../components/button/button";

const StayConnected = ()=>{
    return (
      <>
        <div className="my-[4rem] ">
          <div className="max-w-[77.375rem] mx-auto rounded-[10px] xs:pb-5 sm:pb-5 px-[.5rem] text-white bg-[#59b5cd]">
            <div className="flex justify-between items-center">
              <div className=" mx-auto xs:pt-5 sm:pt-5">
                <h1 className="text-[36px] xs:text-[15px] sm:text-[15px] md:text-[20px] font-[700]">
                  Stay Connected With Zulfah
                </h1>

                <p className="max-w-[31.313rem] xs:text-[12px] sm:text-[15px] py-[2rem] md:text-[15px] text-[20px]">
                  Subscribe to our newsletter for the latest updates, events,
                  and insights in tech education."
                </p>

                <Button
                  btnText={"Enrol Now"}
                  btnClassName={
                    "xs:text-[10px] sm:text-[12px] sm:w-fit sm:h-fit xs:w-fit px-[1rem] xs:h-fit"
                  }
                />
              </div>
              <div>
                <img className="rounded-[10px]" src={stayConnectedImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default StayConnected