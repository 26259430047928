import { zteenzVid } from "../../../assets";
import Button from "../../../components/button/button";
import teens1 from "../../../assets/videos/teens1.mp4"
import teens2 from "../../../assets/videos/teens2.mp4"
import teens3 from "../../../assets/videos/teens3.mp4"


const StudentLedProject = () => {
  return (
    <>
      <div>
        <div className=" max-w-[77.375rem] mx-auto  ">
          <div className="">
            {/* <div>
              <h1 className="max-w-[18.375rem] xs:mx-auto sm:mx:auto md:mx-auto xs:text-center  text-[24px] font-[900] ruda ">
                Student-Led Projects: Solve, Create, Innovate
              </h1>
            </div> */}
            <div className="flex xs:block sm:block md:block items-center gap-[4rem] justify-between mb-[3rem] ">
              <div className="max-w-[26.126rem] xs:mx-auto sm:mx-auto md:mx-auto px-[1rem] py-[2rem] bg-[#f8f8f8]">
                <div className="23.514rem text-[20.39px] xs:text-center sm:text-center md:text-center">
                  <h1 className="text-[18px]  khula font-[800]">
                    Student-Led Projects: Solve, Create, Innovate
                  </h1>
                  <p className="my-[1.5rem] ">
                  
                  Our programs in secondary schools aim to spark
                  curiosity, nurture creativity, and equip students with
                  foundational tech skills that prepare them for a future 
                  in innovation. From engaging workshops to hands-on projects,
                  we’re empowering young minds to dream big and achieve even
                  bigger.


                  </p>
                </div>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfUuvrbrRAHm-fp1O0cJlnlSO21akZDgR7Kt3i1piufsdJi_g/viewform" target="_blank" rel="noreferrer">
                <Button
                  btnText={"Learn More"}
                  btnClassName={"!bg-[#59b5cd] my-[1rem]] xs:text-[12px] px-[1rem] sm:text-[14px] xs:mx-auto sm:mx-auto sm:w-fit !w-fit !h-fit"}
                />
                </a>
              </div>
              <div className="flex xs:flex-col sm:flex-col md:flex-col gap-5">
                  <div> 
                      <video className="w-[15rem] xs:w-full sm:w-full md:w-full" controls>
                          <source src={teens1} type="video/mp4"/>
                      </video>
                  </div>
                  <div> 
                  <video className="w-[15rem] xs:w-full sm:w-full md:w-full" controls>
                          <source src={teens2} type="video/mp4"/>
                      </video>
                  </div>
                  <div> 
                  <video className="w-[15rem] xs:w-full sm:w-full md:w-full" controls>
                          <source src={teens3} type="video/mp4"/>
                      </video>
                  </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentLedProject;
