import { rightArrow } from "../../../assets";

const WhatTeensCard = ({
  img,
  title,
  description,
}) => {
  return (
    <>
      <div className="max-w-[16rem] bg-[#ecb32c] text-[#000] rounded-[13.4px]  py-[1rem] px-[1rem]">
        <div>
          <img src={img} alt="" />
        </div>
        <div className="">
          <h1 className="text-[17.42px] font-[700] text-[#000]">{title}</h1><br />
          <p className="text-[13.4px] font-[300] max-w-[12.649rem]">
            {description}
          </p>
          <div className="flex gap-1 items-center">
            {/* <h1 className="tex-[15.8px] text-[#cf375d] font-[600]">
              Register
            </h1> */}
            <img src={ rightArrow } alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatTeensCard;
