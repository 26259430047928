import { zeroOne, zeroThree, zeroTwo, zulfahStamp } from "../../../assets";

const HowItWorks = () => {
  return (
    <>
      <div className="relative  max-w-[77.063rem] mx-auto mb-[5rem]  my-[3rem] ">
        <div className="flex xs:block sm:block md:block  justify-between">
          <div className="xs:text-center sm:text-center md:text-center mb-[1rem]">
            <h1 className="text-[40px] xs:text-[24.45px]   ruda font-[900]">
              How it works
            </h1>
            <br />
            <br />
            <img
              className="xs:mx-auto sm:mx-auto md:mx-auto "
              src={zulfahStamp}
              alt=""
            />
          </div>
          <div className="max-w-[32rem] xs:text-center md:mx-auto sm:text-center md:text-center">
            <div className="relative max-w-[38.125rem]  xs:max-w-[20rem] pt-[3rem] border-t-2">
              <div className="absolute top-6 xs:left-[2rem] sm:left-[5rem] md:left-[5rem] -left-[5rem] -z-10">
                <img src={zeroOne} alt="" />
              </div>
              <div className="z-50 max-w-[30rem] md:mx-auto pb-2">
                <h1 className="text-[24px] pb-2 font-[600] montserat">
                  You apply
                </h1>
                <p className="text-[12px] xs:mx-auto sm:mx-auto md:mx-auto max-w-[12.125rem]">
                  We screen all submissions to identify high-potential
                  candidates
                </p>
              </div>
            </div>
            <div className="relative max-w-[38.125rem] pt-[3rem] border-t-2">
              <div className="absolute sm:left-[5rem] md:left-[5rem] xs:left-[2rem] top-6 -left-[5rem] -z-10">
                <img src={zeroTwo} alt="" />
              </div>
              <div className="z-50 max-w-[30rem] md:mx-auto pb-2">
                <h1 className="text-[24px] pb-2 montserat font-[600]">
                  Interview - Selection
                </h1>
                <p className="text-[12px] sm:mx-auto xs:mx-auto md:mx-auto max-w-[12.125rem]">
                  You get a chance to convince us, then, we make our decision
                </p>
              </div>
            </div>
            <div className="relative max-w-[38.125rem] pt-[3rem] border-t-2">
              <div className="absolute sm:left-[5rem] xs:left-[2rem] md:left-[5rem] top-6 -left-[5rem] -z-10">
                <img src={zeroThree} alt="" />
              </div>
              <div className="z-50 max-w-[30rem] md:mx-auto pb-2">
                <h1 className="text-[24px] pb-2 montserat font-[600]">
                  Training - Certification
                </h1>
                <p className="text-[12px] xs:mx-auto sm:mx-auto md:mx-auto max-w-[12.125rem]">
                  After six months, you will earn a Diploma cerificate. You will
                  also have internship opportunities
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="absolute -bottom-[10rem] xs:-bottom-[18rem] sm:-bottom-[18rem] md:-bottom-[18rem]">
          <img src={zulfahStamp} alt="" />
        </div> */}
      </div>
    </>
  );
};

export default HowItWorks;
