import { courseDescription, curriculumOverview, keySkill } from "../../../assets";
import Button from "../../../components/button/button";
import { Link } from "react-router-dom";

const CourseDescription = () =>{
    return (
      <>
        <div className="mt-[4rem] xs:text-center sm:text-center md:text-center lg:text-center xs:mx-auto">
          <div className="">
            {/* <img src={courseDescription} alt="" /> */}
            <h1 className="text-[24px] font-[900]  ruda">Course Description</h1>
          </div>

          <div className="max-w-[44.375rem]    text-[24px] xs:text-[17px] sm:text-[19px]">
            <h1 className=" font-[700] pb-2 mt-[2rem]">
              What Does a Software Developer Do?
            </h1>

            <p className="font-[300]">
              Software developers are the architects behind web and mobile
              applications. They design and implement both the visual and
              functional aspects of applications, managing everything from user
              interfaces to backend operations. <br />
              Developers work with coding languages like JavaScript and SQL to
              ensure seamless integration, content management, and performance.
              As a software developer, you will:
            </p>
          </div>

          <ul className="max-w-[45.625rem] my-[1rem] xs:text-[15px] sm:text-[18px] text-[20px] font-[300] pl-[2.2rem] list-disc">
            <li>
              Implement industry-standard practices such as source control and
              issue tracking.
            </li>
            <li>
              Build front-end web applications from scratch or using frameworks.
            </li>
            <li>
              Develop full-stack single-page applications (SPAs) with AJAX
              communication.
            </li>
            <li>
              Create session-based applications with user authentication
              systems.
            </li>
            <li>Deploy dynamic and static websites to the cloud.</li>
            <li>Troubleshoot legacy code and enhance functionality.</li>
            <li>
              Write SQL queries for data management (Create, Read, Update,
              Delete).
            </li>
          </ul>

          <div className="max-w-[44.5rem] my-[1rem] xs:text-[15px] sm:text-[18px] text-[20px]">
            <h1 className="font-[700]">
              What Will You Learn in a Coding Class?
            </h1>
            <p className="font-[300]">
              Our coding bootcamp provides an in-depth exploration of
              fundamental coding concepts, data structures, and hands-on
              experience with modern technologies. You’ll gain proficiency in
              front-end tools like HTML, CSS, and JavaScript, and back-end
              technologies including Node.js and MySQL databases.
            </p>
          </div>

          <div className="">
            {/* <img src={curriculumOverview} alt="" /> */}
            <h1 className="text-[24px] xs:text-[17px] sm:text-[19px] font-[900] ruda my-[1.5rem]">
              Curriculum Overview
            </h1>
          </div>
          <div className="max-w-[35.125rem] xs:mx-auto sm:mx-auto md:mx-auto text-[20px] xs:text-[15px] sm:text-[18px]">
            <div>
              <h1 className="font-[600]">Computer Science & JavaScript:</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  Algorithms, Performance, Time Complexity, Big O Notation
                </li>
                <li>Data Structures, Design Patterns</li>
              </ul>
            </div>{" "}
            <br />
            <div>
              <h1 className="font-[600]">API Design:</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Client-Server Model, REST, JSON, AJAX, GraphQL</li>
                <li>HTTP Request Methods</li>
              </ul>
            </div>
            <br />
            <div>
              <h1 className="font-[600]">Databases:</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>MySQL, MongoDB, Redis</li>
              </ul>
            </div>{" "}
            <br />
            <div>
              <h1 className="font-[600]">Browser-Based Technologies:</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  HTML, CSS, JavaScript, React.js, jQuery, Responsive Design
                </li>
                <li>Bootstrap, Tailwind, Progressive Web Apps (PWAs)</li>
                <li>LocalStorage, sessionStorage, IndexedDB</li>
              </ul>
            </div>{" "}
            <br />
            <div>
              <h1 className="font-[600]">Deployment & Delivery:</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Heroku, AWS, Git & GitHub, Shell Scriptingnsive Design</li>
                <li>Unit Testing, Continuous Integration</li>
              </ul>
            </div>{" "}
            <br />
            <div>
              <h1 className="font-[600]">Server-Side Development:</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>MERN Stack (MongoDB, Express.js, React.js, Node.js)</li>
                <li>User Authentication, Template Engines</li>
              </ul>
            </div>{" "}
            <br />
          </div>
        </div>

        <div className="xs:text-center sm:text-center md:text-center lg:text-center xs:mx-auto">
          {/* <img src={keySkill} alt="" /> */}
          <h1 className="text-[24px] xs:text-[17px] sm:text-[19px] font-[900] ruda my-[1.5rem]">
            Key Skills You Will Develop
          </h1>
        </div>
        <div className="text-[20px] xs:text-center sm:text-center md:text-center lg:text-center xs:text-[15px] sm:text-[18px] font-[300]">
          <ul className="list-disc  pl-[2.2rem] ">
            <li>
              Soft Skills: Adaptability, Business Acumen, Critical Thinking,
              Communication, Problem Solving, Organization
            </li>

            <li>
              Hard Skills: API Interaction, JavaScript Fundamentals, Database
              Management, Command-Line & Deployment, Server-Side Development,
              Front-End Technologies (HTML, CSS, JavaScript)
            </li>
          </ul>
          <br />
          <p className="py-[1rem] xs:text-center sm:text-center md:text-center lg:text-center xs:mx-auto">
            Upon completing our Software Engineering Bootcamp, you will be
            equipped with the tools and skills necessary to thrive as a
            successful web developer.
          </p>
        </div>
        <br />
        <div className="max-w-[44.5rem] my-[1rem] xs:text-[15px] sm:text-[18px] text-[20px]">
          <h1 className="font-[700]">Cost of the Course:</h1>
          <br />
          <p className="font-[300]">
            <ul className="list-disc font-[300] pl-[2.2rem]">
              <li>
                Backend Software Development :{" "}
                <span className="font-bold">#750,000</span>
              </li>
              <li>
                Frontend Software Development :{" "}
                <span className="font-bold">#750,000</span>
              </li>
              <li>
                Fullstack Software Development :{" "}
                <span className="font-bold">#1,300,000</span>
              </li>
            </ul>
          </p>
        </div>
        <Link to="/get-started">
          <Button
            btnText={"Join Now"}
            btnClassName={
              "!text-[18px]  !h-fit !w-fit px-4 xs:text-[13px] sm:text-[16px] !bg-[#ecb32c] mt-[2rem]"
            }
          />
        </Link>
        <br />
      </>
    );
};

export default CourseDescription;