import { courseDescription, curriculumOverview, keySkill } from "../../../assets";
import Button from "../../../components/button/button";
import { Link } from "react-router-dom";
const CourseDescription = () =>{
    return (
      <>
        <div className="mt-[4rem] xs:text-center sm:text-center md:text-center lg:text-center xs:mx-auto">
          <div className="">
            {/* <img src={courseDescription} alt="" /> */}
            <h1 className="text-[24px] font-[900] ruda">Course Description</h1>
          </div>

          <div className="max-w-[44.375rem] text-[20px]">
            <h1 className=" font-[700] mt-[2rem]">
              What does a Cybersecurity Analyst do?
            </h1>

            <p className="font-[300]">
              Cybersecurity analysts are part of the first line of defense
              against cyber attacks and play a vital role in keeping both
              company and consumer data safe. These professionals are experts at
              assessing threats, mitigating risks, and quickly responding to
              breaches through a variety of programs and techniques. As a
              cybersecurity analyst you will:
            </p>
          </div>

          <ul className="max-w-[45.625rem] my-[2rem] xs:text-[15px] sm:text-[18px] text-[20px] font-[300] pl-[2.2rem] list-disc">
            <li>
              Analyze packet traffic flowing over a network in order to better
              troubleshoot issues such as poor network performance.
            </li>
            <li>
              Conduct vulnerability assessments using tools like Metasploit to
              profile an application for vulnerabilities, and then exploit those
              vulnerabilities.
            </li>
            <li>
              Configure machines on a virtual network, deploy them to the cloud,
              and investigate cloud security risks.
            </li>
            <li>
              Gain insight into the important best practices around password
              selection and storage to crack into (mock!) user accounts.
            </li>
            <li>
              Identify suspicious patterns of user behavior to identify bots,
              intruders, and other malicious actors.
            </li>
            <li>
              Perform administrative and security tasks to Windows and Linux
              Operating Systems.
            </li>
            <li>
              Perform network, system, and web penetration testing activities.
            </li>
            <li>
              Write Bash scripts to automate security and operating systems
              tasks.
            </li>
          </ul>

          <div className="max-w-[44.5rem] my-[1rem] xs:text-[15px] sm:text-[18px] text-[20px]">
            <h1 className="font-[700]">
              What is taught in a cybersecurity class?
            </h1>
            <p className="font-[300]">
              Our cybersecurity boot camp is designed to give you a strong
              foundation in network and system security, and our live
              classes(physical or online) emphasize the real-world applications
              of cybersecurity through hands-on exercises and projects.You’ll
              learn to use cybersecurity tools including Wireshark, Kali Linux,
              Metasploit, and Nessus, and gain key skills applicable to
              certifications such as the CompTIA Security+, Network+, Linux+,
              Server+, Cloud+, and Certified Ethical Hacker (CEH).
            </p>
          </div>

          <div className="">
            {/* <img src={curriculumOverview} alt="" /> */}
            <h1 className="text-[24px] xs:text-[17px] sm:text-[19px] font-[900] ruda my-[1.5rem]">
              Curriculum Overview
            </h1>
          </div>
          <div className="max-w-[35.125rem] xs:mx-auto sm:mx-auto md:mx-auto text-[20px] xs:text-[15px] sm:text-[18px]">
            <div>
              <h1 className="font-[600]">Module 1: Security fundamentals</h1>
              <p>
                Learn to think like a cybersecurity professional by assessing
                threats and mitigating risks. You will learn:
              </p>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>CIA triad</li>
                <li>Governance</li>
                <li>Risk analysis and risk mitigation</li>
                <li>Compliance</li>
                <li>Business continuity planning</li>
                <li>Disaster recovery</li>
              </ul>
            </div>

            <div>
              <h1 className="font-[600]">Module 2: Systems Administration</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Linux server configuration</li>
                <li>Windows server configuration</li>
                <li>Tar, cron, and CronJobs</li>
                <li>Logging</li>
                <li>Bash scripting and programming</li>
                <li>Active directory</li>
                <li>Kerberos</li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">
                Module 3: Networks and network security
              </h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Network architecture, operations, and security</li>
                <li>Wireshark and traffic analysis</li>
                <li>Email security </li>
                <li>Wireless security </li>
                <li>Cryptography and encryption</li>
                <li>Port scanning</li>
                <li>Cloud security and virtualization </li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">Module 4: Defensive security</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Splunk </li>
                <li>Monitoring and logging</li>
                <li>Incident response</li>
                <li>Security operations</li>
                <li>Forensics</li>
                <li>Data extraction and recovery</li>
                <li>Security information and event management (SIEM)</li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">Module 5: Offensive security</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Burp Suite</li>
                <li>XSS vulnerabilities and payloads</li>
                <li>SQL injection</li>
                <li>Webshells</li>
                <li>File inclusion and command injection vulnerabilities</li>
                <li>Penetration testing execution standard</li>
                <li>SearchSploit and Metasploit</li>
                <li>Zenmap</li>
                <li>Pivoting networks</li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">
                Module 6: Test prep and final projects
              </h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  Focus on certification prep for Security+ and CEH exams, and
                  conclude the program with a final group project.
                </li>
                <li>
                  Our Cybersecurity (grad) analysts are not only in charge of
                  monitoring databases and networks to keep them safe, but they
                  also have to be able to effectively communicate any
                  vulnerabilities or attacks to clients.{" "}
                </li>
                <li>
                  Once you complete the our cybersecurity bootcamp, you will
                  learn key skills needed to become an effective cybersecurity
                  analyst. These skills include:
                </li>
              </ul>
              <br />
              <p> Software Skills</p>
              <ul className="list-disc font-[300] pl-[5rem]">
                <li> Attention to detail </li>
                <li>Creativity </li>
                <li>Collaboration and teamwork </li>
                <li>Communication </li>
                <li>Organization </li>
                <li>Problem solving</li>
              </ul>

              <p> Hard Skills</p>
              <ul className="list-disc font-[300] pl-[5rem]">
                <li>Cloud security </li>
                <li>Databases </li>
                <li>Intrusion detection </li>
                <li> Network security </li>
                <li>Operating systems </li>
                <li>System administration</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="max-w-[44.5rem] my-[1rem] xs:text-[15px] sm:text-[18px] text-[20px]">
          <h1 className="font-[700]">Cost of the Course:</h1>
          <br />
          <p className="font-[300]">
            <ul className="list-disc font-[300] pl-[2.2rem]">
              <li>
                Cybersecurity - weekdays{" "}
                <span className="font-bold">#450,000</span>
              </li>
              <li>
                Cybersecurity - weekends{" "}
                <span className="font-bold">#500,000</span>
              </li>
            </ul>
          </p>
        </div>
        <Link to="/get-started">
          <Button
            btnText={"Join Now"}
            btnClassName={
              "!text-[18px] !h-fit !w-fit px-4 xs:text-[13px] sm:text-[16px] !bg-[#ecb32c] mt-[2rem]"
            }
          />
        </Link>
        <br />
      </>
    );
};

export default CourseDescription;