import { certified, countDown, cybersecImg1, deviceIcon, devopsImg1, videoIcon, webdevImg1 } from "../../assets";
import Button from "../button/button";
import courseIntro from "../../assets/videos/course_intro.mp4";
import { Link } from "react-router-dom";

const Card = ({ courseImg }) => {
  return (
    <>
      <div className="max-w-[29.188rem] mx-auto border border-[#d9d9d9] mt-[4rem] rounded-[11.65px] py-[1.5rem] px-[1.5rem] bg-[#f8f8f8]">
        <div className="max-w-[34.553rem] mx-auto ">
          {/* <img src={devopsImg1} alt="" /> */}
          <video src={courseIntro} controls className="w-full h-[15rem] object-cover" />
        </div>
        
          {/* <div className="">
          <h1 className="text-[17.12px] text-[#040707] font-[700]">
            This Course Include
          </h1>
        </div>
        <div className="text-[14px] font-[300]">
          <div className="flex  gap-2">
            <img src={videoIcon} alt="" />
            <h1>25 In-Depth Video Lessons</h1>
          </div>
          <div className="flex gap-2">
            <img src={deviceIcon} alt="" />
            <h1>Access on Any Devices</h1>
          </div>
          <div className="flex gap-2">
            <img src={countDown} alt="" />
            <h1>Full-Time Access</h1>
          </div>
          <div className="flex gap-2">
            <img src={certified} alt="" />
            <h1>Certification</h1>
          </div>
        </div> */}
        
      </div>
      <Link to='/get-started'>
      <Button btnText={"Join Course"}  btnClassName={"!h-[2rem] py-5 text-[17.28px] !py-[0rem] my-[1rem] !w-full"} />
       </Link>
    </>
  );
};

export default Card;