import { Link } from "react-router-dom";
import { cyberSecurityFirstPageImg, devopFirstPageImg, softwareEngPageFirstImg } from "../../assets";
import Button from "../../components/button/button";
import Card from "../../components/card";
import SecondCard from "../../components/courses-second-card";
import Layout from "../../components/shared/layout/layout";
import CourseDescription from "./component/course-description";

const Devops = () =>{
    return (
      <>
        <Layout>
          <div className="max-w-[77.375rem] bg-[#ecb32c] mx-auto rounded-[10px]">
            <div className="flex items-center justify-between xs:py-2">
              <div className="pl-[2rem] text-white ">
                <h1 className="text-[36px] sm:text-[25px] font-[700] ">
                  DevOps
                </h1>
                <Link to={"/get-started"}>
                  <Button
                    btnText={"Beginner Friendly"}
                    btnClassName={
                      "!text-[18px] xs:text-[12px] sm:text-[14px] !bg-[#ecb32c] mt-[2rem] "
                    }
                  />
                </Link>
              </div>
              <div className="xs:hidden">
                <img src={devopFirstPageImg} alt="img" />
              </div>
            </div>
          </div>

          <div className="max-w-[77.375rem] mx-auto">
            {/* <div className=" w-fit lg:hidden xl:hidden xs:mx-auto sm:mx-auto md:mx-auto ">
              <Card />
            </div> */}
            <div className="flex xs:items-center sm:items-center md:items-center xs:flex-col sm:flex-col md:flex-col gap-4  ">
              <div className="">
                <CourseDescription />
                <div className="my-[1rem] w-fit lg:hidden xs:mx-auto sm:mx-auto md:mx-auto">
                  {/* <SecondCard /> */}
                </div>
              </div>
              <div className="xs:items-center sm:items-center md:items-center">
                <Card />
                <SecondCard />
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
};

export default Devops;