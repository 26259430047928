import { courseDescription, curriculumOverview, keySkill } from "../../../assets";
import Button from "../../../components/button/button";
import { Link } from "react-router-dom";

const CourseDescription = () =>{
    return (
      <>
        <div className="mt-[4rem] xs:text-center sm:text-center md:text-center lg:text-center xs:mx-auto">
          <div className="">
            <h1 className="text-[24px] font-[900] ruda">Course Description</h1>
          </div>

          <div className="max-w-[44.375rem] text-[20px]">
            <h1 className=" font-[700] mt-[2rem]">
              What Does a Web3 Developer Do?
            </h1>

            <p className="font-[300] ">
              Web3 developers are pioneers in the decentralized web, building
              applications that run on blockchain technology. They design and
              implement both the visual and functional aspects of decentralized
              applications (dApps), ensuring secure, transparent, and efficient
              operations. Developers in this field work with smart contracts,
              distributed systems, and cutting-edge frameworks to create
              user-friendly solutions for the next generation of the internet.
              As a Web3 developer, you will:
            </p>
          </div>

          <ul className="max-w-[45.625rem] my-[2rem] xs:text-[15px] sm:text-[18px] text-[20px] font-[300] pl-[2.2rem] list-disc">
            <li>
              Develop smart contracts using Solidity and deploy them on Ethereum
              or other blockchain networks.
            </li>
            <li>
              Build decentralized applications (dApps) with Web3.js, Ethers.js,
              and frameworks like Hardhat.
            </li>
            <li>
              {" "}
              Integrate blockchain functionality into web applications for
              seamless user experiences.
            </li>
            <li>
              Implement secure user authentication using decentralized identity
              (DID) solutions.
            </li>
            <li>
              Work with Layer 2 scaling solutions and cross-chain protocols.
            </li>
            <li>
              Utilize IPFS for decentralized storage and build token standards
              like ERC-20 and ERC-721.
            </li>
            <li>Debug and audit smart contracts to ensure secure operations</li>
          </ul>

          <div className="max-w-[44.5rem] my-[1rem] xs:text-[15px] sm:text-[18px] text-[20px]">
            <h1 className="font-[700]">
              What Will You Learn in a Web3 Development Class?
            </h1>
            <p className="font-[300]">
              Our Web3 bootcamp immerses you in the core principles of
              blockchain and decentralized systems. You’ll gain hands-on
              experience with blockchain development tools, learn about
              distributed ledger technologies, and master the art of building
              and deploying decentralized applications (dApps).
            </p>
          </div>

          <div className="">
            <h1 className="text-[24px] xs:text-[17px] sm:text-[19px] font-[900] ruda my-[1.5rem]">
              Curriculum Overview
            </h1>
          </div>
          <div className="max-w-[35.125rem] text-[20px] xs:text-[15px] sm:text-[18px] xs:mx-auto sm:mx-auto md:mx-auto">
            <div>
              <h1 className="font-[600]">
                Blockchain Basics & Smart Contracts:
              </h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  Understanding Blockchain Technology and Distributed Ledgers{" "}
                </li>
                <li>
                  Introduction to Cryptography, Hashing, and Consensus
                  Mechanisms
                </li>
                <li>Writing and Deploying Smart Contracts with Solidity</li>
                <li>
                  Token Development: ERC-20, ERC-721, and ERC-1155 Standards
                </li>
              </ul>
            </div>

            <div>
              <h1 className="font-[600]">
                Decentralized Application Development (dApps):
              </h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  Client-Smart Contract Interaction using Web3.js and Ethers.js
                </li>
                <li>Building dApps with React.js and Web3 Integration </li>
                <li>Decentralized Identity and Authentication (DID, ENS)</li>
                <li>Real-Time Data Interaction with Oracles</li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">Blockchain Platforms & Protocols:</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Ethereum, Binance Smart Chain, Polygon, and Solana</li>
                <li>
                  Understanding Layer 2 Solutions (Optimistic Rollups,
                  zk-Rollups)
                </li>
                <li>Interoperability and Cross-Chain Development</li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">Storage & Infrastructure:</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Decentralized Storage with IPFS and Filecoin </li>
                <li>Blockchain Node Setup and Maintenance</li>
                <li>Deploying dApps on Testnets and Mainnets</li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">Security & Testing:</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  Debugging and Auditing Smart Contracts with tools like Hardhat
                  and Truffle
                </li>
                <li>
                  Security Best Practices: Preventing Reentrancy and Overflows
                </li>
                <li>Unit Testing with Mocha and Chai</li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">Economics & Governance in Web3:</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Tokenomics: Designing Token Incentive Models</li>
                <li>
                  Decentralized Autonomous Organizations (DAOs): Implementation
                  and Governance
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="xs:text-center sm:text-center md:text-center lg:text-center xs:mx-auto">
          <h1 className="text-[24px] xs:text-[17px] sm:text-[19px] font-[900] ruda my-[1.5rem]">
            Key Skills You Will Develop
          </h1>
        </div>
        <div className="xs:text-center sm:text-center md:text-center lg:text-center text-[20px] xs:text-[15px] sm:text-[18px] font-[300]">
          <ul className="list-disc  pl-[2.2rem] ">
            <li>Soft Skills:</li>
            Innovation and Adaptability, Blockchain Ecosystem Understanding,
            Collaboration within Decentralized Communities
            <li>Hard Skills:</li>
            <li> Smart Contract Development with Solidity</li>
            <li>dApp Development using Web3.js and React.js</li>
            <li>Blockchain Deployment and Management</li>
            <li>Token Standard Implementation (ERC-20, ERC-721)</li>
            <li>Secure Smart Contract Testing and Debugging</li>
            <li>Decentralized Identity and Authentication</li>
          </ul>
          <p className="py-[1rem] xs:text-center sm:text-center md:text-center lg:text-center xs:mx-auto">
            Upon completing our Web3 Development Bootcamp, you will be equipped
            to thrive in the emerging world of blockchain technology and
            decentralized applications, positioning yourself as a key player in
            the future of the internet.
          </p>
        </div>

        <div className="max-w-[44.5rem] my-[1rem] xs:text-[15px] sm:text-[18px] text-[20px]">
          <h1 className="font-[700]">Cost of the Course:</h1>
          <br />
          <p className="font-[300]">
            <ul className="list-disc font-[300] pl-[2.2rem]">
              <li>
                Web3 Technology : <span className="font-bold">#750,000</span>
              </li>
            </ul>
          </p>
        </div>
        <Link to="/get-started">
          <Button
            btnText={"Join Now"}
            btnClassName={
              "!text-[18px] !h-fit !w-fit px-4 xs:text-[13px] sm:text-[16px] !bg-[#ecb32c] mt-[2rem]"
            }
          />
        </Link>

        <br />
      </>
    );
};

export default CourseDescription;