import { useRef, useState } from "react";
import {
  carolselLeftArrow,
  carolselRightArrow,
  testimonialVidImg,
} from "../../../assets";
import { testimonialVideo } from "../../about-us/components/testimonialVideo";

const Testimonial = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isPlaying1, setIsPlaying1] = useState(true);
  const [isPlaying2, setIsPlaying2] = useState(true);
  const [isPlaying3, setIsPlaying3] = useState(true);
  const [isPlaying4, setIsPlaying4] = useState(true);
  const [isPlaying5, setIsPlaying5] = useState(true);
  const targetRef = useRef();
  const targetRef1 = useRef();
  const targetRef2 = useRef();
  const targetRef3 = useRef();
  const targetRef4 = useRef();
  const targetRef5 = useRef();
  
  const handleHideModal = (e) => {
    console.log(targetRef.current.contains(e.target));
    if (targetRef.current.contains(e.target)) {
      setIsPlaying(false);
    }
  };

  const handleShowModal = (e) => {
    console.log(!targetRef.current.contains(e.target));
    if (targetRef.current.contains(e.target)) {
      setIsPlaying(true);
    }
  };
  const handleHideModal1 = (e) => {
    console.log(targetRef1.current.contains(e.target));
    if (targetRef1.current.contains(e.target)) {
      setIsPlaying1(false);
    }
    console.log("playigF",isPlaying1)
  };

  const handleShowModal1 = (e) => {
    console.log(!targetRef1.current.contains(e.target));
    if (targetRef1.current.contains(e.target)) {
      setIsPlaying1(true);
    }
    console.log("playigT",isPlaying1)
  };
  const handleHideModal2 = (e) => {
    console.log(targetRef1.current.contains(e.target));
    if (targetRef2.current.contains(e.target)) {
      setIsPlaying2(false);
    }
    console.log("playigF",isPlaying1)
  };

  const handleShowModal2 = (e) => {
    console.log(!targetRef1.current.contains(e.target));
    if (targetRef2.current.contains(e.target)) {
      setIsPlaying2(true);
    }
    console.log("playigT",isPlaying1)
  };
  const handleHideModal3 = (e) => {
    console.log(targetRef1.current.contains(e.target));
    if (targetRef3.current.contains(e.target)) {
      setIsPlaying3(false);
    }
    console.log("playigF",isPlaying1)
  };

  const handleShowModal3 = (e) => {
    console.log(!targetRef1.current.contains(e.target));
    if (targetRef3.current.contains(e.target)) {
      setIsPlaying3(true);
    }
    console.log("playigT",isPlaying1)
  };
  const handleHideModal4 = (e) => {
    console.log(targetRef1.current.contains(e.target));
    if (targetRef4.current.contains(e.target)) {
      setIsPlaying4(false);
    }
    console.log("playigF",isPlaying1)
  };

  const handleShowModal4 = (e) => {
    console.log(!targetRef1.current.contains(e.target));
    if (targetRef4.current.contains(e.target)) {
      setIsPlaying4(true);
    }
    console.log("playigT",isPlaying1)
  };
  const handleHideModal5 = (e) => {
    console.log(targetRef1.current.contains(e.target));
    if (targetRef5.current.contains(e.target)) {
      setIsPlaying5(false);
    }
    console.log("playigF",isPlaying1)
  };

  const handleShowModal5 = (e) => {
    console.log(!targetRef1.current.contains(e.target));
    if (targetRef5.current.contains(e.target)) {
      setIsPlaying5(true);
    }
    console.log("playigT",isPlaying1)
  };

  return (
    <>
      <div>
        <div>
          <h1 className="text-[40px] ruda text-center font-[900] my-[2rem]">
            Testimonial
          </h1>
        </div>
        <div className="overflow-x-hidden w-full ">
          <div
            className="flex relative hover:animate-none  animate-slide  gap-5 w-[200%] no-scrollbar "
            // style={{ transform: `translateX(-${ 10}%)` , }}
          >
            

            <div className="">
              <div
                ref={targetRef}
                onMouseOut={handleShowModal}
                onMouseEnter={handleHideModal}
                className="relative max-w-[43rem] bg-[#f8f8f8] rounded-[10px]"
              >
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/2fl6RujPaJM?si=ooKtdj-iR1Dcyh1d"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>

                {isPlaying && (
                  <div className="absolute top-[3rem] right-[1rem] ">
                    <div
                      className={`bg-[#fefefe] rounded-[10px] text-[16px] font-[400] px-[1rem] py-[1rem] w-[20.894rem]`}
                    >
                      <h1>
                        “ Thanks to Zulfah, my life has been completely transformed. 
                          I went from having zero knowledge of tech to becoming a full-stack
                          developer. This program opened doors I never thought possible, 
                          and today, I am proud to be part of Flutterwave as a software
                           engineer. The journey has been incredible, and I’m truly grateful 
                           for everything Zulfah has done for me.”.
                      </h1>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className=" ">
              <div
                ref={targetRef1}
                onMouseOut={handleShowModal1}
                onMouseEnter={handleHideModal1}
                className="relative max-w-[43rem] bg-[#f8f8f8] rounded-[10px]"
              >
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/HRNUatoLjt4?si=TO_XatAZAb5shp4v"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>

                {isPlaying1 && (
                  <div className="absolute top-[3rem] right-[1rem] ">
                    <div
                      className={`bg-[#fefefe] rounded-[10px] text-[16px] font-[400] px-[1rem] py-[1rem] w-[20.894rem]`}
                    >
                      <h1>
                        “ Before Zulfah, I have no prior
                         experience or understanding of programming. My background felt 
                         worlds away from the tech industry, but joining this program 
                         changed everything for me. The curriculum was thoughtfully 
                         structured, and the mentorship was second to none. I’ll forever be 
                         grateful to Zulfah for helping me rewrite my story and build a future
                         I’m proud of."
                      </h1>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className=" ">
              <div
                ref={targetRef2}
                onMouseOut={handleShowModal2}
                onMouseEnter={handleHideModal2}
                className="relative max-w-[43rem] bg-[#f8f8f8] rounded-[10px]"
              >
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/-J1gJw9ffGE?si=n-IGJU1eQcVXnl71"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>

                {isPlaying2 && <div className="absolute top-[3rem] right-[1rem] ">
                  <div
                    className={`bg-[#fefefe] rounded-[10px] text-[16px] font-[400] px-[1rem] py-[1rem] w-[20.894rem]`}
                  >
                    <h1>
                      “ After my Undergrad, it was not clear what I wanted to
                      do. I opted for a career switch and Zulfah has helped me
                      build a career in Software Engineering. I presently work
                      as a frontend engineer at herVest and it's been really
                      amazing journey from biochemistry to Software developer. I
                      can confidently say I made the right decision “.
                    </h1>
                  </div>
                </div>}
              </div>
            </div>
            <div className="">
              <div
                ref={targetRef3}
                onMouseOut={handleShowModal3}
                onMouseEnter={handleHideModal3}
                className="relative max-w-[43rem] bg-[#f8f8f8] rounded-[10px]"
              >
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/2fl6RujPaJM?si=ooKtdj-iR1Dcyh1d"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>

                {isPlaying3 && (
                  <div className="absolute top-[3rem] right-[1rem] ">
                    <div
                      className={`bg-[#fefefe] rounded-[10px] text-[16px] font-[400] px-[1rem] py-[1rem] w-[20.894rem]`}
                    >
                      <h1>
                        "Joining Zulfah was the best decision I ever made. 
                        I went from knowing nothing about tech to becoming a 
                        skilled full-stack developer. The hands-on training and
                        mentorship prepared me for real-world challenges, and now,
                        I’m proud to be working as a software engineer at Interswitch.
                        Zulfah didn’t just teach me tech skills—it changed my life."
                      </h1>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className=" ">
              <div
                ref={targetRef4}
                onMouseOut={handleShowModal4}
                onMouseEnter={handleHideModal4}
                className="relative max-w-[43rem] bg-[#f8f8f8] rounded-[10px]"
              >
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/HRNUatoLjt4?si=TO_XatAZAb5shp4v"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>

                {isPlaying4 && (
                  <div className="absolute top-[3rem] right-[1rem] ">
                    <div
                      className={`bg-[#fefefe] rounded-[10px] text-[16px] font-[400] px-[1rem] py-[1rem] w-[20.894rem]`}
                    >
                      <h1>
                        “ After my Undergrad, it was not clear what i wanted to
                        do. I opted for a career switch and Zulfah has helped me
                        build a career in Software Engineering. I presently work
                        as a frontend engineer at herVest and it's been really
                        amazing journey from biochemistry to Software developer.
                        I can confidently say I made the right decision “.
                      </h1>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className=" ">
              <div
                ref={targetRef5}
                onMouseOut={handleShowModal5}
                onMouseEnter={handleHideModal5}
                className="relative max-w-[43rem] bg-[#f8f8f8] rounded-[10px]"
              >
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/-J1gJw9ffGE?si=n-IGJU1eQcVXnl71"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>

                {isPlaying5 && <div className="absolute top-[3rem] right-[1rem] ">
                  <div
                    className={`bg-[#fefefe] rounded-[10px] text-[16px] font-[400] px-[1rem] py-[1rem] w-[20.894rem]`}
                  >
                    <h1>
                      “ After my Undergrad, it was not clear what i wanted to
                      do. I opted for a career switch and Zulfah has helped me
                      build a career in Software Engineering. I presently work
                      as a frontend engineer at herVest and it's been really
                      amazing journey from biochemistry to Software developer. I
                      can confidently say I made the right decision “.
                    </h1>
                  </div>
                </div>}
              </div>
            </div>

            {/* <div
              ref={targetRef}
              onMouseOut={handleShowModal}
              onMouseEnter={handleHideModal}
              className=""
            >
              <div className="relative max-w-[43rem] bg-[#f8f8f8] rounded-[10px]">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/2fl6RujPaJM?si=ooKtdj-iR1Dcyh1d"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>

                {isPlaying && <div className="absolute top-[3rem] right-[1rem] ">
                  <div
                    className={`bg-[#fefefe] rounded-[10px] text-[16px] font-[400] px-[1rem] py-[1rem] w-[20.894rem]`}
                  >
                    <h1>
                      “ Thanks to Zulfah, my life will forever be changed. I
                      went from zero knowledge of tech to a full stack
                      developer. I loved this program and everything it's done
                      for me.From zero knowlegde to joining flutterwave as a
                      software engineer ”.
                    </h1>
                  </div>
                </div>}
              </div>
            </div>

            <div className=" ">
              <div
                ref={targetRef}
                onMouseOut={handleShowModal}
                onMouseEnter={handleHideModal}
                className="relative max-w-[43rem] bg-[#f8f8f8] rounded-[10px]"
              >
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/HRNUatoLjt4?si=TO_XatAZAb5shp4v"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>

                {isPlaying && <div className="absolute top-[3rem] right-[1rem] ">
                  <div
                    className={`bg-[#fefefe] rounded-[10px] text-[16px] font-[400] px-[1rem] py-[1rem] w-[20.894rem]`}
                  >
                    <h1>
                      “ After my Undergrad, it was not clear what i wanted to
                      do. I opted for a career switch and Zulfah has helped me
                      build a career in Software Engineering. I presently work
                      as a frontend engineer at herVest and it's been really
                      amazing journey from biochemistry to Software developer. I
                      can confidently say I made the right decision “.
                    </h1>
                  </div>
                </div>}
              </div>
            </div> */}
          </div>
        </div>

        <div className="flex justify-center gap-[1rem] my-[2rem]">
          {/* <img src={carolselLeftArrow} alt="" />
            <img src={carolselRightArrow} alt="" /> */}
        </div>
      </div>
    </>
  );
};

export default Testimonial;
