import { rightArrow, webDev, whatIsTeensImg } from "../../../assets";
import Button from "../../../components/button/button";
import WhatTeensCard from "./what-teens-card";

const WhatsIsZTeens = () =>{
    return (
      <>
        <div className="">
          <div className="flex  xs:block sm:block md:block items-center gap-4 max-w-[77.375rem] mx-auto  mb-[6rem]">
            <div className="xs:mx-auto xs:w-fit sm:mx-auto sm:w-fit md:mx-auto md:w-fit md:py-2">
              <img src={whatIsTeensImg} alt="" />
            </div>
            <div className="max-w-[37.5rem] lg:w-[27rem] xs:mx-auto  sm:mx-auto md:mx-auto">
              <h1 className="text-[32px]  font-[900] ruda xs:text-[18px] xs:text-center sm:text-[20px] sm:py-2  xs:py-2 sm:text-center">
                {/* Zulfah ZTeens? */}
              </h1>
              <p className="text-[24px] lg:text-[18px] xs:text-[16px] xs:text-center  sm:text-[18px] sm:text-center md:text-center sm:py-2  xs:py-2 khula font-[300]">
                ZTeens is a vibrant and engaging software engineering club
                program tailored for schools and students aged 13-18, where
                innovation and creativity come to life!
                <br />
                <br></br> Our mission is to spark curiosity and equip young
                minds with essential coding, design, and problem-solving skills
                through exciting, hands-on projects, team challenges, and
                real-world mentorship. Whether your students are tech beginners
                or already skilled, ZTeens offers something exciting for
                everyone. From app development and game creation to website
                design and more, this program transforms ideas into reality
                while preparing students for a tech-driven future. Let’s bring
                the world of technology to your school!
              </p>
              {/* <h1 className="text-[24px] xs:text-[18px] xs:text-center sm:text-[20px] sm:text-center md:text-center font-[600] ">
                {" "}
                Ready to start creating?
              </h1> */}
              {/* <div className="xs:flex xs:justify-center  sm:flex sm:justify-center md:flex md:justify-center ">
                <Button
                  btnText={"Join the Z Teens Club Today!"}
                  btnClassName={
                    "!w-fit px-[1rem] xs:text-[14px] sm:text-[16px] sm:mx-auto md:mx-auto xs:mx-auto mt-[1rem]"
                  }
                />
              </div> */}
            </div>
          </div>
          {/* <div className="max-w-[77.375rem] flex xs:block sm:block md:block justify-between px-[3rem] py-[3rem] rounded-[10px] mx-auto my-[3rem] bg-[#cf375d]">
            <div className="max-w-[19rem] text-white">
              <h1 className="text-[20px] ">Explore Our Exciting Activities</h1>
              <p>
                At Z Teens, explore hands-on activities that let you create,
                innovate, and solve real-world problems—whether it's building
                apps, designing websites, or competing in hackathons.
              </p>
            </div>
            <div className="flex max-w-[50.625rem] overflow-x-auto no-scrollbar xs:pt-2 sm:pt-2 md:pt-2 gap-2 items-center">
              <WhatTeensCard
                img={webDev}
                title={"Mobile App Development"}
                description={
                  "Start your tech journey with easy-to-follow coding lessons.No experience needed!"
                }
              />
              <WhatTeensCard
                img={webDev}
                title={"Web App Development"}
                description={
                  "Start your tech journey with easy-to-follow coding lessons.No experience needed!"
                }
              />
              <WhatTeensCard
                img={webDev}
                title={"Mobile App Development"}
                description={
                  "Start your tech journey with easy-to-follow coding lessons.No experience needed!"
                }
              />
            </div>
          </div> */}
        </div>
      </>
    );
};

export default WhatsIsZTeens