const dashboardPathName = "/";
const coursedPathName = "/courses";
const softwareEngineeringPathName = "/software-engineering";
const cyberSecurityPathName = "/cyber-security";
const webDevelopPathName = "/web-development";
const web3TechnolgyPathName = "/web3-technology";
const devopsPathName = "/devops";
const zTeensPathName = "/z-teens";
const resourcePathname = "/resources";
const aboutPathname = "/about-us";
const dataSciencePathname = "/data-science";
const projectPathname = "/project";
const getStarted = "/get-started";
const contactUsPathName = "/contact-us";


export {
  dashboardPathName,
  zTeensPathName,
  devopsPathName,
  webDevelopPathName,
  cyberSecurityPathName,
  coursedPathName,
  dataSciencePathname,
  softwareEngineeringPathName,
  web3TechnolgyPathName,
  resourcePathname,
  aboutPathname,
  projectPathname,
  getStarted,
  contactUsPathName,
};