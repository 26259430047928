import Footer from "../footer/footer";
import Navbar from "../nav-bar/navbar";

const Layout = ({ children }) => {
  return (
    <>
      <div className="sm:px-[.5rem] px-[1rem] xs:px-[.5rem]">
        <Navbar />
        {children}
        <Footer />
      </div>
    </>
  );
};

export default Layout;
