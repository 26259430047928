import { courseDescription, curriculumOverview, keySkill } from "../../../assets";
import Button from "../../../components/button/button";
import { Link } from "react-router-dom";

const CourseDescription = () =>{
    return (
      <>
        <div className="mt-[4rem] xs:text-center sm:text-center md:text-center lg:text-center xs:mx-auto">
          <div className="">
            {/* <img src={courseDescription} alt="" /> */}
            <h1 className="text-[24px] font-[900] ruda text-center ">Course Description</h1>
          </div>

          <div className="max-w-[44.375rem] text-[20px]">
            <h1 className=" font-[700] mt-[2rem]">
              What does an AI professional do?
            </h1>

            <p className="font-[300]">
              AI professionals develop, implement, and maintain artificial
              intelligence (AI) solutions and systems. Their responsibilities
              include designing, creating, and optimizing AI models and
              algorithms to solve complex problems and automate tasks. As an AI
              professional, you will:
            </p>
          </div>

          <ul className="max-w-[45.625rem] my-[2rem] xs:text-[15px] sm:text-[18px] text-[20px] font-[300] pl-[2.2rem] list-disc">
            <li>
              Explore and experiment with new algorithms, models, and
              techniques.
            </li>
            <li>
              Gather relevant data for AI projects and perform data cleaning,
              preprocessing, and feature engineering to ensure data quality and
              suitability for training models.
            </li>
            <li>
              Develop full-stack single-page applications (SPAs) with AJAX
              communication.
            </li>
            <li>
              Design, develop, and optimize AI models using machine learning,
              deep learning, and other AI techniques.
            </li>
            <li>
              Train AI models using large datasets and fine-tune them to improve
              accuracy and performance.
            </li>
            <li>
              Identify the most suitable algorithms and frameworks for specific
              AI tasks.
            </li>
            <li>
              Implement algorithms and optimize them for efficient execution and
              scalability.
            </li>
            <li>
              Use frameworks and programming languages like Python to develop AI
              applications, algorithms, and prototypes
            </li>
            <li>
              Analyze and interpret AI-generated insights, identify patterns,
              and extract meaningful information from data.
            </li>
            <li>
              Address ethical considerations related to AI, such as privacy,
              bias, and fairness, and ensure responsible AI development and
              deployment.
            </li>
          </ul>

          <div className="max-w-[44.5rem] my-[1rem] xs:text-[15px] sm:text-[18px] text-[20px]">
            <h1 className="font-[700]">
              What is taught in our Artificial Intelligence class?
            </h1>
            <p className="font-[300]">
              Our AI boot camps explore the fundamentals of artificial
              intelligence, and provide learners with a comprehensive
              understanding of real-world AI concepts and techniques. You’ll
              learn essential topics such as machine learning algorithms, deep
              learning architectures, natural language processing, and
              programming languages like Python.
            </p>
          </div>

          <div className="">
            {/* <img src={curriculumOverview} alt="" /> */}
            <h1 className="text-[24px] font-[900] xs:text-[17px] sm:text-[19px] ruda my-[1.5rem]">
              The AI boot camp curriculum
            </h1>
          </div>
          <div className="max-w-[45.625rem] text-[20px] xs:text-[15px] sm:text-[18px]">
            <div>
              <h1 className="">
                Our curriculum covers a wide range of key topics and programs
                for AI exploration. Here’s a list of in-demand skills the boot
                camp will cover:
              </h1>
              <br />
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Machine learning </li>
                <li>Python</li>
                <li>Algorithms</li>
                <li>Deep learning</li>
                <li>TensorFlow</li>
                <li>Agile</li>
                <li>Data science</li>
                <li>Natural language processing</li>
              </ul>
            </div>
            <br />

            <div>
              <h1 className="font-[600]">What is the salary outlook in AI?</h1>
              <br />
              <p>
                AI is big, and is growing fast. According to GrandViewResearch,
                AI is expected to see an annual growth rate of 37.3% from 2023
                to 2030. While specific salary figures may vary depending on job
                setting, location, and company, AI professionals can expect
                competitive compensation. Here are a few of the top industries
                leveraging AI technologies:
              </p>
              <br />
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Technology and IT services</li>
                <li>Financial services</li>
                <li>Healthcare and biotechnology</li>
                <li>E-commerce and retail</li>
                <li>Manufacturing and industrial automation</li>
              </ul>
            </div>
            <br />
          </div>

          <br />

          <div className="max-w-[45.625rem] text-[20px] xs:text-[15px] sm:text-[18px]">
            <p className="font-normal text-[20px] xs:text-[15px] sm:text-[18px]">
              Our Artificial Intelligence professionals develop, implement, and
              optimize artificial intelligence solutions. Though many aspiring
              AI professionals do not need a college degree to work in the
              field, technical knowledge and training are typically required.
              Once you complete our AI boot camp, you will have learned the key
              skills needed to become a successful AI professional. These
              includes the following:
            </p>
            <br />
            <p className="text-[20px]">Technical Skills</p>
            <ul className="list-disc font-[300] text-[20px] pl-[2.2rem]">
              <li>Programming proficiency</li>
              <li>Machine learning and deep learning</li>
              <li>Data science and analytics</li>
              <li>Mathematics and statistics</li>
              <li>Algorithm design</li>
              <li>Data management</li>
              <li>Natural language processing</li>
            </ul>
            <br />
            <p className="text-[20px]"> Soft Skills</p>
            <ul className="list-disc  font-[300] text-[20px] pl-[2.2rem]">
              <li>Critical thinking</li>
              <li>Creativity</li>
              <li>Collaboration</li>
              <li>Adaptability</li>
              <li>Ethical awareness </li>
              <li>Problem solving</li>
            </ul>
          </div>
          <br />

          <div className="max-w-[44.5rem] my-[1rem] xs:text-[15px] sm:text-[18px] text-[20px]">
            <h1 className="font-[700]">Cost of the Course:</h1>
            <br />
            <p className="font-[300]">
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  Artificial Intelligence :{" "}
                  <span className="font-bold">#750,000</span>
                </li>
              </ul>
            </p>
          </div>
          <Link to="/get-started">
            <Button
              btnText={"Join Now"}
              btnClassName={
                "!text-[18px] !h-fit !w-fit px-4 xs:text-[13px] sm:text-[16px] !bg-[#ecb32c] mt-[2rem]"
              }
            />
          </Link>
        </div>

        <br />
      </>
    );
};

export default CourseDescription;