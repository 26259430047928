import AboutUs from "../pages/about-us";
import Courses from "../pages/courses";
import CyberSecurity from "../pages/cyber-security";
import Dashboard from "../pages/dashboard";
import DataScience from "../pages/data-science";
import Devops from "../pages/devops";
import Projects from "../pages/projects";
import Resources from "../pages/resources";
import SoftwareEngineering from "../pages/software-engineering";
import Web3Technology from "../pages/web3";
import WebDevelopment from "../pages/artificial-intelligence";
import ZTEENS from "../pages/z-teens";
import GetStarted from "../pages/get-started";
import ContactUs from "../pages/contact-us"
import { aboutPathname, coursedPathName, cyberSecurityPathName, dashboardPathName, dataSciencePathname, devopsPathName, projectPathname, 
  resourcePathname, softwareEngineeringPathName, web3TechnolgyPathName,
   webDevelopPathName, zTeensPathName, getStarted, contactUsPathName } from "./pathName";

const pageRoutes = [
  {
    path: dashboardPathName,
    element: Dashboard,
  },
  {
    path: coursedPathName,
    element: Courses,
  },
  {
    path: softwareEngineeringPathName,
    element: SoftwareEngineering,
  },
  {
    path: cyberSecurityPathName,
    element: CyberSecurity,
  },
  {
    path: webDevelopPathName,
    element: WebDevelopment,
  },
  {
    path: devopsPathName,
    element: Devops,
  },
  {
    path: web3TechnolgyPathName,
    element: Web3Technology,
  },
  {
    path: dataSciencePathname,
    element: DataScience,
  },
  {
    path: zTeensPathName,
    element: ZTEENS,
  },
  {
    path: resourcePathname,
    element: Resources,
  },
  {
    path: aboutPathname,
    element: AboutUs,
  },
  {
    path: projectPathname,
    element: Projects,
  },
  {
    path: getStarted,
    element: GetStarted,
  },
  {
    path: contactUsPathName,
    element: ContactUs
  }
];


export {
    pageRoutes
}