import { resourcePageFirstImg } from "../../../assets";

const ResourceCard = ({ watchTime, date, pText, pText2, aLink, aText, img, title, video=null }) => {
  return (
    <>
      <div className="max-w-[23.188rem] xs:my-2 sm:my-2 md:my-2">
        <div>
         { 
          video == null ? <img src={img}  alt="" /> :  
            <iframe
                style={{width: "100%", height: "230px"}}
                src={video}
                title="Zulfah in the news"
                frameborder="0"
                allowfullscreen="true"
                autoPlay="false"
              ></iframe> 
         
        
         }
        </div>
        <div className="max-w-[23.188rem]  mt-[2rem]">
          <div className="flex items-center gap-3">
            <div className="w-[2.101rem] h-[2.101rem] bg-[#d9d9d9] rounded-full"></div>
            <h1 className="text-[10.96px] ruda">{title}</h1>
            <div className="w-[4px]  h-[4px] rounded-full bg-[#d9d9d9]"></div>
            <p className="text-[10.08px] text-[#d9d9d9]">{watchTime}</p>
          </div>
          <p className="text-[16.08px]  xs:text-center sm:text-center md:text-center  xs:block sm:block md:block xs:w-fit sm:w-fit md:w-fit xs:mx-auto sm:mx-auto md:mx-auto xs:text-[14px] sm:text-[16px] md:text-[18px] font-[400]">
            {pText}
          </p>
          <p className="text-[q6.08px]  xs:text-center sm:text-center md:text-center  xs:block sm:block md:block xs:w-fit sm:w-fit md:w-fit xs:text-[14px] sm:text-[16px] md:text-[18px] font-[400]">
            {/* {pText2} */}
            <a href={aLink} target="_blank" rel="noreferrer" className="text-blue-500">{aText}</a>
          </p>

          <h1 className="text-[16.3px] text-[#d9d9d9]">{date}</h1>
        </div>
      </div>
    </>
  );
};


export default ResourceCard;