import { upcomingEvent } from "../../../assets";
import Button from "../../../components/button/button";

const  UpcomingEvents = () =>{
    return (
      <>
        <div>
          <div
            className="max-w-[66.563rem] mx-auto my-[2rem]
          "
          >
            <h1 className="text-[32px] xs:text-[20px] sm:text-[25px]  font-[800] text-center ruda my-[1rem]">
              {/* Upcoming Events */}
            </h1>
            <br />
            <div className="flex  xs:block sm:block md:block items-center gap-4 bg-[#ecb32c] rounded-[11.74px]">
              <div className="bg-[#59b5cd] pt-[2rem] rounded-tl-[11.74px] rounded-bl-[11.74px]">
                <img
                  src={upcomingEvent}
                  className="rounded-bl-[11.74px] xs:mx-auto sm:mx-auto md:mx-auto  "
                  alt=""
                />
              </div>
              <div className="text-[24px] xs:py-2 sm:py-2 md:py-2 xs:text-center sm:text-center md:text-center font-[400] px-[.5rem] xs:text-[18px] sm:text-[18px] md:text-[18px]">
                <h1 className="text-[32px]  font-[800] ruda">
                  Z Teens Hackathon
                </h1>
                <p className="">
                Collaborate in teams to create innovative projects and win exciting prizes.
                </p>
              
                <Button
                  btnText={"Join Now"}
                  btnClassName={
                    "text-[18px] xs:text-[14px] sm:text-[14px] md:text-[14px] my-[1rem] font-[600] !bg-[#59b5cd]"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};


export default UpcomingEvents