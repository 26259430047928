const Button = ({ type, btnText, imgBef, imgAft, btnClassName, onClick }) => {
  return (
    <>
      <div className="">
        <button
          className={`text-[20px] text-white w-[10.25rem] h-[3.75rem] bg-opacity-[86%] py-[1rem]  rounded-[10px] bg-[#ecb32c] ${btnClassName} ${
            (imgBef || imgAft) && "flex items-center gap-2"
          }`}
          type={type ? type : "button"}
          onClick={onClick}
        >
          {imgBef && <img src={imgBef} alt="" />}

          {btnText}
          {imgAft && <img src={imgAft} alt="" />}
        </button>
      </div>
    </>
  );
};

export default Button;
