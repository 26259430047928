import { careerSupport, handOn, inovationCurriculum, monitorInYellowCircle } from "../../assets";

const whyZulfah = [
  {
    whyZulIcon: monitorInYellowCircle,
    heading: "Expert-Led Training",
    details:
      "Learn from industry professionals with real-world experience, ensuring you gain practical skills",
  },
  {
    whyZulIcon: inovationCurriculum,
    heading: "Innovative Curriculum",
    details:
      "Stay ahead of industry trends with programs covering cutting-edge technologies like AI and data science.",
  },
  {
    whyZulIcon: handOn,
    heading: "Hands-On Learning",
    details:
      "Engage in project-based learning to tackle real-world challenges and prepare for the tech industry.",
  },
  {
    whyZulIcon: careerSupport,
    heading: "Career Support",
    details:
      "Access mentorship, networking opportunities, and personalized guidance to help you achieve your career goals.",
  },
];

export default whyZulfah