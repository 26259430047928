import { aboutUsFirstImg, aboutUsSecondImg } from "../../assets";
import Layout from "../../components/shared/layout/layout";
import StayConnected from "../dashboard/component/stay-connected";
import MeetTheTeam from "./components/meet-the-board";
import OurCores from "./components/our-cores.";
import zee2 from "../../assets/zee2.png";
import rosh from "../../assets/rosh2.jpeg";

const AboutUs = () => {
  return (
    <>
      <Layout>
        <div className="max-w-[79.313rem] mx-auto mt-[5rem] xs:text-center sm:text-center md:text-center">
          <div className="flex xs:flex-col sm:flex-col md:flex-col gap-5">
            {/* <h1 className="text-center text-[50px] font-[900] ruda">
              About Us
            </h1> */}
            <div className="w-5/12 xs:w-full  sm:w-full md:w-full">
              <h1 className="text-[48px]  font-[600]">
                Empowering the Next Generation of Tech Leaders...
              </h1>
            </div>
            <div className="w-8/12 xs:w-full sm:w-full md:w-full">
              <p className="text-[18px] leading-[37px] font-[300]">
                Founded with a mission to train the next billion Africans to
                become top-tier tech talents. At Zulfah Academy, we believe that
                technology is the key to unlocking potential and driving
                innovation.
                <br />
                <br />
                Over the past years, we have trained over 500 students across
                Nigeria, the USA, the UK, and Canada in cutting-edge fields like
                Software Engineering, Cybersecurity, and Artificial
                Intelligence. Our graduates have gone on to achieve remarkable
                success, securing roles in leading global companies like
                Flutterwave, Interswitch, Lyft, and MTN. These success stories
                inspire us to keep pushing the boundaries of tech education and
                create even more opportunities for aspiring tech professionals.
              </p>
            </div>
          </div>
          <div className="my-[4rem]  md:flex md:justify-center ">
            {/* <img src={aboutUsFirstImg} alt="" /> */}
          </div>

          <div className="flex xs:block lg:block sm:block md:block items-center justify-between">
            <div className="md:flex lg:flex lg:justify-center md:justify-center">
              <img src={aboutUsSecondImg} alt="" />
            </div>
            <div className=" max-w-[39.313rem] lg:mx-auto md:mx-auto xs:text-center sm:text-center md:text-center lg:text-center">
              <div className="py-[3rem]">
                <h1 className="text-[20px] font-[800] ruda"> Mission</h1>
                <p className="text-[18px] leading-[37px] font-[300]">
                  The mission is to deliver high-quality, cutting-edge tech
                  education that empowers students with the knowledge and skills
                  needed to excel in the evolving technology landscape. We are
                  committed to fostering a supportive and inclusive environment,
                  where learners are encouraged to innovate, collaborate, and
                  achieve excellence.
                </p>
              </div>
              <div className="py-[3rem]">
                <h1 className="text-[20px] font-[800] ruda"> Vision</h1>
                <p className="text-[18px] leading-[37px] font-[300]">
                  Our vision is to be a global leader in tech education and
                  innovation, shaping the future of the digital world by
                  cultivating a generation of tech-savvy professionals and
                  leaders. We strive to be at the forefront of technological
                  advancements, ensuring that our students are well-prepared to
                  drive impactful change and innovation in their careers.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row max-w-[79.313rem] mx-auto">
          <img src={zee2} alt="" />
          {/* <div className="w-[50%]"> 
          <h1 className="text-[24px] font-[600] p-5"> What We Offer:</h1>
          <ul className="list-disc text-[18px] leading-[37px] font-[300] pl-[2.2rem]">
            <li> Comprehensive training programs in Software Engineering, Cybersecurity, and Artificial Intelligence.</li>
            <li>Hands-on learning with real-world projects to prepare students for industry challenges.</li>
            <li>Flexible learning options, including physical classes and weekend programs, to accommodate diverse schedules.</li>
            <li>Career guidance and mentorship to help students navigate their tech journey.</li>
              
            </ul>
          </div>
          <div className="w-[50%]"> 
             <img src="" />
          </div> */}
        </div>

        <div>
          <div className="flex xs:flex-col sm:flex-col md:flex-col max-w-[79.313rem] xs:text-center sm:text-center md:text-center mx-auto my-[4rem]">
            {/* <h1 className="text-center text-[50px] font-[900] ruda">
              About Us
            </h1> */}
            <div className="lg:w-4/12 xs:w-full sm:w-full md:w-full">
              <h1 className="text-[48px] font-[600]">Our Vision for 2030...</h1>
            </div>
            <div className="w-8/12 xs:w-full sm:w-full md:w-full">
              <p className="text-[18px] leading-[37px] font-[300]">
                We believe that knowledge empowers, and through innovative,
                hands-on training in fields like Software Engineering,
                Cybersecurity, and Artificial Intelligence, we enable our
                students to unlock their full potential. Our goal is not only to
                prepare them for careers in tech but to inspire them to become
                change-makers in their communities and catalysts for
                socio-economic development.
                <br />
                <br />
                By 2030, we aim to have empowered one million individuals across
                Africa with the skills they need to thrive in the digital
                economy, shaping their futures while making a lasting impact on
                the continent. Through education, innovation, and a commitment
                to excellence, Zulfah Academy is building a brighter, more
                prosperous future for Africa.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="bg-[#1a202c]">
          lkdfjvkdf
        </div> */}
        {/* <div className="flex flex-row max-w-[79.313rem] mx-auto">
          <div className="w-[50%]"> 
            <img src="" />
          </div>
          <div className="w-[50%]"> 
          <h1 className="text-[24px] font-[600] p-5"> Why Choose Zulfah Academy?</h1>
          <ul className="list-disc text-[18px] leading-[37px] font-[300] pl-[2.2rem]">
            <li>Expert-Led Training: Our experienced instructors bring years of industry knowledge and passion for teaching.</li>
            <li>Proven Success: Our alumni network spans top tech firms and startups worldwide.</li>
            <li>Community-Driven: We foster a supportive and collaborative learning environment where students thrive.</li>
              
            </ul>


          </div>
        </div> */}
        {/* <OurCores /> */}
        <br />
        <br />
        {/* <MeetTheTeam /> */}
        <StayConnected />
      </Layout>
    </>
  );
};

export default AboutUs;
