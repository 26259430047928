import { zulfalFooterLogo } from "../../../assets";
import { Link } from "react-router-dom";
import WhatsAppButton from "../../chat-us/whatsApp";
const phoneNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
const Footer = () =>{
    return (
      <>
        <WhatsAppButton
          phoneNumber={phoneNumber}
          className={"fixed right-5 bottom-7"}
          message={"Hello, I need more enquiries about Zulfah Academy!"}
        />
        <div className="bg-[#cf375d] figtree px-[1rem] text-white xs:text-center sm:text-center md:text-center lg:text-center xs:mx-auto">
          <div className="flex xs:flex-col sm:flex-col md:flex-col lg:flex-col lg:items-center xs:items-center sm:items-center md:items-center flex-wrap justify-between max-w-[77.5rem] mx-auto py-[5.5rem] ">
            <div className="max-w-[8.625rem] md:my-[1rem] xs:flex-col sm:flex-col  md:flex-col lg:flex-col xs:items-center sm:items-center md:items-center mb-[2rem]">
              <img
                src={zulfalFooterLogo}
                className="bg-white sm:mx-auto md:mx-auto xs:mx-auto lg:mx-auto"
                alt="zulfah logo"
              />
              <h1 className="text-white pt-[3rem]">
                © 2024 Zulfah, Inc. All rights reserved.
              </h1>
            </div>

            <div className="flex flex-wrap xs:flex-col sm:flex-col md:flex-col xs:items-center sm:items-center md:items-center   justify-between max-w-[62.625rem] md:py-[1rem] lg:py-[1rem] sm:py-[1rem] xs:py-[1rem] ">
              <div className="xs:hidden sm:hidden space-y-4 xs:w-fit w-[19.625rem]">
                <h1 className="font-[600] text-[15px] xs:text-[18px] sm:text-[18px] md:text-[18px]">
                  Quick Links
                </h1>
                <h1>
                  <Link to="/">Home</Link>
                </h1>
                <h1>
                  <Link to="/about-us">About</Link>
                </h1>
                <h1>
                  <Link to="/resources">Resources</Link>
                </h1>
                <h1>
                  <Link to="/contact-us">Contact</Link>
                </h1>
              </div>

              <div className="xs:hidden sm:hidden space-y-4 xs:w-fit w-[19.625rem] md:py-[1rem] lg:py-[1rem] sm:py-[1rem] xs:py-[1rem]">
                <h1 className="font-[600] figtree text-[15px] xs:text-[18px] sm:text-[18px] md:text-[18px]">
                  Explore More
                </h1>
                <h1>
                  <a href="#faq">FAQs</a>
                </h1>
                <h1>Careers</h1>
                <h1>Blog</h1>
                <h1>Partners</h1>
              </div>

              <div className="space-y-4 xs:w-fit w-[19.625rem] lg:pt-[1rem] md:py-[1rem] lg:py-[1rem] sm:py-[1rem] xs:py-[1rem]">
                <h1 className="font-[600] text-[15px] xs:text-[18px] sm:text-[18px] md:text-[18px]">
                  Connect With Us
                </h1>
                <h1>
                  <a href="https://web.facebook.com/profile.php?id=100076343703349">
                    Facebook
                  </a>
                </h1>
                <h1>
                  <a href="https://x.com/@zulfahng">Twitter</a>
                </h1>
                <h1>
                  {" "}
                  <a href="https://www.linkedin.com/company/zulfah-group/">
                    LinkedIn
                  </a>{" "}
                </h1>
                <h1>
                  <a href="https://www.instagram.com/zulfahng/profilecard/?igsh=MWV4cjJyaXVoa2RhNg==">
                    Instagram
                  </a>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default Footer;