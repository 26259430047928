import { gasContainerIcon } from "../../../assets";

const GasLitPoints = ({ img, title, description }) => {
  return (
    <>
      <div className="flex items-center justify-between gap-[1rem] my-[2rem]">
        <div>
          <img src={img} alt="" />
        </div>
        <div className="max-w-[31.582rem]">
          <h1 className="text-[20px] font-[600] leading-[40px] ">
            {title} – <span className="font-[400]">{description}</span>
          </h1>
        </div>
      </div>
    </>
  );
};

export default GasLitPoints