import Layout from "../../components/shared/layout/layout";
import { useState } from "react";
import axios from "axios";

import {
  countries,
  devYears,
  paymentOptions,
  processStages,
  qualifications,
  hearAboutUs,
  programmeTypes,
  programmes,
} from "../../helpers/lists";
import spaceDev from "../../assets/space-dev.png";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { rightArrow, yellowArrowLeft } from "../../assets";
import { useNavigate } from "react-router-dom";

function Index() {
  const [surname, setSurname] = useState("");
  const [othernames, setOthernames] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("default country");
  const [address, setAddress] = useState("default address");
  const [city, setCity] = useState("default city");
  const [countryState, setCountryState] = useState("default state");
  const [postalcode, setPostalCode] = useState("default postal code");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("2021-01-01");
  const [highestQualification, setHighestQualification] = useState("others");
  //const [university, setUniversity] = useState("")
  const [course, setCourse] = useState("");
  const [infoMedium, setInfoMedium] = useState("");
  const [devExperience, setDevExperience] = useState("");
  const [programmingLanguage, setProgrammingLanguage] = useState(
    "default programming language"
  );
  const [paymentOption, setPaymentOption] = useState("SIP");
  const [programmeType, setProgrammeType] = useState("");
  const [programme, setProgramme] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [progress, setProgress] = useState("not started");
  const [error, setError] = useState("");
  const BaseUrl = "https://api.zulfahgroup.com/api/v1";

  const navigate = useNavigate();

  const handleClickBack = () => {
    navigate(-1);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitted(true);

    if (
      !surname ||
      !othernames ||
      !phoneNumber ||
      !email ||
      !gender ||
      !programme ||
      !programmeType ||
      !devExperience ||
      !infoMedium
    ) {
      toast.error("All fields are required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: "Bounce",
      });
      return;
    } else {
      try {
        const { data } = await axios({
          method: "post",
          url: `${BaseUrl}/application/create`,
          data: {
            surname,
            othernames,
            address,
            sex: gender,
            phone: phoneNumber,
            dob,
            country,
            city,
            current_location: address,
            state: countryState,
            highest_qualification: highestQualification,
            years_of_programming: devExperience,
            programming_language: "Nil",
            email,
            where_you_heard_about_us: infoMedium,
            available_in_six_month: true,
            programme_type: programmeType,
            programme: programme,
            payment_option: paymentOption,
          },
        });

        setIsSubmitted(false);
        toast.success(data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: "Bounce",
        });

        Swal.fire({
          title: "Application Submitted",
          text: "Your application has been submitted successfully",
          icon: "success",
          confirmButtonColor: "#ECB32C",
          confirmButtonText: "Got it",
        }).then((result) => {
          if (result.value) {
            window.location.href = `/`;
          }
        });
      } catch (error) {
        setIsSubmitted(false);
        toast.error(error.response.data.message || "Something went wrong");
      }
    }
  }

  return (
    <>
      {/* <Layout> */}
      <div className="max-w-[77.375rem] mx-auto rounded-[10px]">
        <div className="flex md:flex-col xs:flex-col items-center gap-10 xs:py-2">
          <div className=" w-4/12 pl-[2rem] xs:hidden sm:hidden md:hidden text-black bg-[#cf375d] h-screen p-10 text-center">
            <img src={spaceDev} alt="Apply Now" />
            <h1 className="text-[30px] text-[#fff]">
              Master high-demand tech skills and unlock a world of endless
              possibilities.
            </h1>
            <br />
            <br />
            <p className=" text-center text-[#fff]">
              © 2024 ZulfahAcademy.
              <br /> All rights reserved
            </p>
          </div>
          <div className=" relative w-8/12 xs:w-full md:w-full sm:w-full p-5 ">
            {/* create header here  */}

            <div
              onClick={handleClickBack}
              className="flex cursor-pointer gap-3 absolute left-[2rem] -top-[2rem] xs:left-[3rem] sm:left-[3rem] md:left-[3rem] xs:top-2 sm:top-2 md:top-2 "
            >
              <img src={yellowArrowLeft} alt="" />
              Back
            </div>

            <p className="px-8 xs:pt-5 sm:pt-5 md:pt-5">
              <h1 className="text-[30px] text-[#000] font-bold">Apply Now</h1>
              <p className="  text-[#636363]">
                You are just a few clicks away starting your tech journey 🥳
              </p>
            </p>
            <form
              className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
              onSubmit={handleSubmit}
            >
              <div className="flex xs:flex-col sm:flex-col md:flex-col justify-between gap-3 py-2">
                <div className="mb-4 w-full">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="username"
                  >
                    Surname
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="surname"
                    name="surname"
                    type="text"
                    placeholder="Surname"
                    required
                    onChange={(e) => setSurname(e.target.value)}
                  />
                </div>
                <div className="mb-4 w-full">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="username"
                  >
                    Othernames
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="othernames"
                    name="othernames"
                    type="text"
                    placeholder="Othernames"
                    required
                    onChange={(e) => setOthernames(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex xs:flex-col sm:flex-col md:flex-col  justify-between gap-3 py-2">
                <div className="mb-4 w-full">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="username"
                  >
                    Phone
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    name="phone"
                    type="text"
                    placeholder="Phone"
                    required
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
                <div className="mb-4 w-full">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="username"
                  >
                    Email
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex xs:flex-col sm:flex-col md:flex-col  justify-between gap-3 py-2">
                <div className="mb-4 w-full">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="username"
                  >
                    Course
                  </label>
                  <select
                    className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="course"
                    name="course"
                    required
                    onChange={(e) => setProgramme(e.target.value)}
                  >
                    <option value="">Select Course</option>
                    <option value="Frontend">
                      Frontend Software development
                    </option>
                    <option value="Backend">
                      Backend Software development
                    </option>
                    <option value="Fullstack">
                      Fullstack Software development
                    </option>
                    <option value="Cybersecurity">Cybersecurity</option>
                    <option value="Artificial Intelligence">
                      Artificial Intelligence
                    </option>
                    <option value="Blockchain">Blockchain & Web3.0</option>
                    <option value="DevOps">DevOps</option>
                  </select>
                </div>

                <div className="mb-4 w-full">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="username"
                  >
                    Type
                  </label>
                  <select
                    className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="programme_type"
                    name="programme_type"
                    required
                    onChange={(e) => setProgrammeType(e.target.value)}
                  >
                    <option value=""> Programme Type</option>
                    <option value="weekdays">Weekdays</option>
                    <option value="weekends">Weekends</option>
                  </select>
                </div>
              </div>
              <div className="flex xs:flex-col sm:flex-col md:flex-col  justify-between gap-3 py-2">
                <div className="mb-4 w-full">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="username"
                  >
                    Gender
                  </label>
                  <select
                    className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="gender"
                    name="gender"
                    required
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div className="mb-4 w-full">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="username"
                  >
                    Programming Experience
                  </label>
                  <select
                    className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="programming_experience"
                    name="programming_experience"
                    required
                    onChange={(e) => setDevExperience(e.target.value)}
                  >
                    <option value="">
                      What is your programming experience like ?
                    </option>
                    <option value="0">0 year</option>
                    <option value="1-2years">1-2 years</option>
                    <option value="3+years">3+ years</option>
                  </select>
                </div>
              </div>
              <div className="flex xs:flex-col sm:flex-col md:flex-col  justify-between gap-3 py-2">
                <div className="mb-4 w-full">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="username"
                  >
                    How did you hear about us ?
                  </label>
                  <select
                    className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                    onChange={(e) => setInfoMedium(e.target.value)}
                  >
                    <option value=""> How did you hear about us ?</option>
                    <option value="facebook">Facebook</option>
                    <option value="linkedin">LinkedIn</option>
                    <option value="twitter">Twitter</option>
                    <option value="an-alumnus">An Alumnus</option>
                    <option value="google-search">Google Search</option>
                  </select>
                </div>
              </div>
              <br />
              <div className="flex items-center justify-between">
                {isSubmitted ? (
                  <button
                    className="bg-[#ecb32c] hover:bg-[#ecb32c] text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                  >
                    {" "}
                    Submitting...
                  </button>
                ) : (
                  <button
                    className="bg-[#ecb32c] hover:bg-[#ecb32c] text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    {" "}
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* </Layout> */}
    </>
  );
}

export default Index;
