import { courseFirstImg } from "../../assets";
import Button from "../../components/button/button";
import Layout from "../../components/shared/layout/layout";
import AllCourses from "./components/software-engineering";
import SoftwareEngineering from "./components/software-engineering";

const Courses = () => {
  return (
    <>
      <Layout>
        <div className="max-w-[77.375rem] py-2 bg-[#ecb32c] mx-auto rounded-[10px]">
          <div className="flex xs:block sm:block xs:mx-auto sm:mx-auto items-center justify-between py-[2rem]">
            <div className="pl-[2rem]  xs:px-2 sm:px-2 text-white basis-[60%]">
              <h1 className="text-[36px] xs:text-[18px] md:text-[20px] lg:text-[20px] sm:text-[18px] font-[700] ">
                Explore Our Cutting-Edge Tech Programs
              </h1>
              <p className="text-[20px] xs:text-[14px] sm:text-[14px] md:text-[14px] lg:text-[14px] py-[1.5rem] font-[400]">
                Master the Skills You Need for a Future in Tech
              </p>
              <Button
                btnText={"Enroll Now"}
                btnClassName={
                  "!bg-[#cf375d] xs:text-[14px] xs:w-fit xs:h-fit xs:px-[1rem] sm:text-[14px] sm:w-fit sm:h-fit sm:px-[1rem]"
                }
              />
            </div>
            <div className="basis-[40%] sm:hidden xs:hidden">
              <img src={courseFirstImg} alt="img" />
            </div>
          </div>
        </div>

        <AllCourses />
      </Layout>
    </>
  );
};

export default Courses;
