import { useNavigate } from "react-router-dom";
import {
  cybersecImg1,
  cybersecImg2,
  devopsImg1,
  devopsImg2,
  softwareEngImg1,
  softwareEngImg2,
  webdevImg1,
  webdevImg2,
} from "../../../assets";
import CourseCard from "./course-card";
const 
AllCourses = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="max-w-[77.375rem] mx-auto my-[3rem]">
        {/* <h1 className="text-[40px] xs:text-[18px]  sm:text-[22px] lg:text-[22px]  font-[800]">
          Software Engineering
        </h1> */}
        <div className="flex gap-2 xs:flex-col sm:flex-col md:flex-col md:items-center xs:items-center sm:items-center  md:w-full justify-between my-[1.5rem]">
          <CourseCard
            to={"/software-engineering"}
            courseImg={softwareEngImg1}
            courseType={"Software Engineering"}
            courseDuration={"6-9 Months"}
            courseDescription={
              "Build a solid foundation in programming, software design, and development practices to create impactful applications. No degree or prior experience is needed — just curiosity and a drive to build a career in tech."
            }
            // numberOfCourse1={"4 Course"}
            // numberOfCourse2={"10 Course"}
            // courseWatchTime={"20hrs. 45mintues"}
            btnText={"Beginner Friendly"}
            handleClick={() => {
              navigate("/software-engineering");
            }}
          />
          <CourseCard
            courseImg={cybersecImg1}
            to={"/cyber-security"}
            courseType={"Cyber Security"}
            courseDuration={"4 Months"}
            courseDescription={
              "Gain the skills and confidence to launch a successful career in cybersecurity with a comprehensive program that blends theoretical knowledge and hands-on training. No degree or prior experience is required - just a passion for learning and a drive to protect the digital world."
            }
            // numberOfCourse1={"4 Course"}
            // numberOfCourse2={"10 Course"}
            // courseWatchTime={"20hrs. 45mintues"}
            btnText={"Beginner Friendly"}
            handleClick={() => {
              navigate("/cyber-security");
            }}
          />
          <CourseCard
            courseImg={devopsImg2}
            to={"/web-development"}
            courseType={"Artificial Intelligence"}
            courseDuration={"6 Months"}
            courseDescription={
              "From machine learning algorithms to neural networks, this program equips you to develop intelligent systems that solve real-world challenges. No prior experience is required - just a passion for innovation and problem-solving in a rapidly evolving field."
            }
            // numberOfCourse1={"4 Course"}
            // numberOfCourse2={"10 Course"}
            // courseWatchTime={"20hrs. 45mintues"}
            btnText={"Beginner Friendly"}
            handleClick={() => {
              navigate("/web-development");
            }}
          />
        </div>
      </div>
      <div className="max-w-[77.375rem] mx-auto my-[3rem]">
        {/* <h1 className="text-[40px] font-[800] sm:text-[22px] lg:text-[22px]">
          Cyber Security
        </h1> */}
        <div className="flex gap-2 xs:flex-col sm:flex-col md:flex-col md:items-center xs:items-center sm:items-center justify-between my-[1.5rem]">
          <CourseCard
            courseImg={cybersecImg1}
            to={"/data-science"}
            courseType={"Data Science"}
            courseDuration={"4 Months"}
            courseDescription={
              "Learn to analyze, visualize, and draw actionable insights from data using advanced tools and techniques. No degree or prior experience is needed - just a passion for curiosisty and problem-solving and a desire to make data-driven decisions."
            }
            // numberOfCourse1={"4 Course"}
            // numberOfCourse2={"10 Course"}
            // courseWatchTime={"20hrs. 45mintues"}
            btnText={"Beginner Friendly"}
            handleClick={() => {
              navigate("/cyber-security");
            }}
          />
          <CourseCard
            courseImg={webdevImg2}
            to={"/devops"}
            courseType={"Web3 technology"}
            courseDuration={"6 Months"}
            courseDescription={
              "This program offers both theoretical knowledge and practical tools to prepare you for careers in decentralized finance (DeFi), NFTs, and more. No prior experience is required - just commitment and a drive to shape the future of technology."
            }
            // numberOfCourse1={"4 Course"}
            // numberOfCourse2={"10 Course"}
            // courseWatchTime={"20hrs. 45mintues"}
            btnText={"Beginner Friendly"}
            handleClick={() => {
              navigate("/devops");
            }}
          />
          <CourseCard
            courseImg={devopsImg1}
            to={"/devops"}
            courseType={"DevOps"}
            courseDuration={"4 Months"}
            courseDescription={
              "Learn to streamline workflows, automate processes, and build scalable systems that enable faster, more reliable deployments. With no prior experience or degree needed, you can confidently step into a career that bridges the gap between development and operations."
            }
            // numberOfCourse1={"4 Course"}
            // numberOfCourse2={"10 Course"}
            // courseWatchTime={"20hrs. 45mintues"}
            btnText={"Beginner Friendly"}
            handleClick={() => {
              navigate("/devops");
            }}
          />
        </div>
      </div>
      {/* <div className="max-w-[77.375rem] mx-auto my-[3rem]">
        <h1 className="text-[40px] font-[800] sm:text-[22px] lg:text-[22px]">
          Artificial Intelligence
        </h1>
        <div className="flex gap-2 xs:block sm:block md:block justify-between my-[1.5rem]">
          <CourseCard
            courseImg={webdevImg1}
            to={"/web-development"}
            courseType={"Software Engineering: Introduction"}
            courseDuration={"6 Months"}
            courseDescription={
              "The Data Science course provides a comprehensive overview of the principles,techniques, and tools involved in analyzing and interpreting data to extract meaningful insights"
            }
            numberOfCourse1={"4 Course"}
            numberOfCourse2={"10 Course"}
            courseWatchTime={"20hrs. 45mintues"}
            btnText={"Beginner"}
            handleClick={() => {
              navigate("/web-development");
            }}
          />
          <CourseCard
            courseImg={webdevImg2}
            to={"/web-development"}
            courseType={"Software Engineering: Introduction"}
            courseDuration={"6 Months"}
            courseDescription={
              "The Data Science course provides a comprehensive overview of the principles,techniques, and tools involved in analyzing and interpreting data to extract meaningful insights"
            }
            numberOfCourse1={"4 Course"}
            numberOfCourse2={"10 Course"}
            courseWatchTime={"20hrs. 45mintues"}
            btnText={"Advanced"}
            handleClick={() => {
              navigate("/web-development");
            }}
          />
        </div>
      </div>
      <div className="max-w-[77.375rem] mx-auto my-[3rem]">
        <h1 className="text-[40px] font-[800] sm:text-[22px] lg:text-[22px]">
          DevOps
        </h1>
        <div className="flex xs:block sm:block gap-2 md:block justify-between my-[1.5rem]">
          <CourseCard
            courseImg={devopsImg1}
            to={"/devops"}
            courseType={"Software Engineering: Introduction"}
            courseDuration={"6 Months"}
            courseDescription={
              "The Data Science course provides a comprehensive overview of the principles,techniques, and tools involved in analyzing and interpreting data to extract meaningful insights"
            }
            numberOfCourse1={"4 Course"}
            numberOfCourse2={"10 Course"}
            courseWatchTime={"20hrs. 45mintues"}
            btnText={"Beginner"}
            handleClick={() => {
              navigate("/devops");
            }}
          />
          <CourseCard
            courseImg={devopsImg2}
            to={"/devops"}
            courseType={"Software Engineering: Introduction"}
            courseDuration={"6 Months"}
            courseDescription={
              "The Data Science course provides a comprehensive overview of the principles,techniques, and tools involved in analyzing and interpreting data to extract meaningful insights"
            }
            numberOfCourse1={"4 Course"}
            numberOfCourse2={"10 Course"}
            courseWatchTime={"20hrs. 45mintues"}
            btnText={"Advanced"}
            handleClick={() => {
              navigate("/devops");
            }}
          />
        </div>
      </div> */}
    </>
  );
};

export default AllCourses;

{
  /* <div className="max-w-[37.475rem] border border-[#d9d9d9] rounded-[11.65px] py-[1.5rem] px-[1.5rem] bg-[#f8f8f8]">
  <div className="max-w-[34.553rem] mx-auto ">
    <img src={softwareEngImg1} alt="" />
  </div>
  <div className="flex justify-between py-[1rem]">
    <h1 className="text-[22px] font-[700]">
      Software Engineering: Introduction
    </h1>
    <h1 className="text-[19.25px] font-[600]">6 Months</h1>
  </div>
  <p className="text-[17.88px] py-[1rem]">
    The Data Science course provides a comprehensive overview of the principles,
    techniques, and tools involved in analyzing and interpreting data to extract
    meaningful insights
  </p>
  <hr className="border border-[#d9d9d9] my-[1.5rem]" />
  <div className="flex py-[1rem] justify-between text-[22px] font-[700]">
    <h1>4 Course</h1>
    <h1>10 Course</h1>
    <h1>20hrs. 45mintues</h1>
  </div> */
}
// </div>;
