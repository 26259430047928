import { blueStar, newOilBanner, orientationProg, progranStart, rightArrow, yellowRightArrow } from "../../../assets";

const OurEvent = () => {
  return (
    <>
      <div className="max-w-[76.688rem] mx-auto my-[2rem]">
        <div className="">
          <h1 className="text-[40px] xs:text-[22.04px] ruda text-center my-[2.5rem] font-[900]">
            {" "}
            Our Recent Events
          </h1>
        </div>
        <div className=" flex gap-4 xs:flex-col flex-wrap xs:items-center md:justify-center xs:py-[1rem] sm:py-[1rem] sm:space-y-4 xs:space-y-4 sm:justify-center justify-between">
          <div className="max-w-[24.438rem] xs:w-fit px-[.5rem] py-[.5rem] rounded-[8.29px] bg-[#f8f8f8] border border-[#e7e7e7]">
            <div className="relative bg-[#ecb32c] w-[23.438rem] xs:h-fit xs:!mx-auto  xs:max-w-[20rem]  h-[18.063rem] bg-opacity-[15%]">
              {/* <div className="absolute -right-5 float-right pr-[1rem] pt-[1rem]">
                <img src={blueStar} alt="" />
              </div> */}
              <div className="xs:w-fit xs:h-fit">
                <img className="" src={newOilBanner} alt="" />
              </div>
            </div>

            {/* <div className="flex  justify-end gap-2 py-[2rem] items-center text-[#ecbc2c]">
              <p>Register</p> <img src={yellowRightArrow} alt="" />
            </div> */}
          </div>

          <div className="max-w-[24.438rem] xs:w-fit px-[.5rem] py-[.5rem] rounded-[8.29px] bg-[#f8f8f8] border border-[#e7e7e7]">
            <div className="relative bg-[#ecb32c]   w-[23.438rem] xs:h-fit xs:!mx-auto  xs:max-w-[20rem] h-[18.063rem] bg-opacity-[15%]">
              {/* <div className="absolute -right-5 float-right pr-[1rem] pt-[1rem]">
                <img src={blueStar} alt="" />
              </div> */}
              <div className="xs:w-fit xs:h-fit">
                <img src={orientationProg} alt="" />
              </div>
            </div>

            {/* <div className="flex justify-end gap-2 py-[2rem] items-center text-[#ecbc2c]">
              <p>Register</p> <img src={yellowRightArrow} alt="" />
            </div> */}
          </div>

          <div className="max-w-[24.438rem] xs:w-fit px-[.5rem] py-[.5rem] rounded-[8.29px] bg-[#f8f8f8] border border-[#e7e7e7]">
            <div className="relative bg-[#ecb32c]  w-[23.438rem] xs:h-fit xs:!mx-auto  xs:max-w-[20rem] h-[18.063rem] bg-opacity-[15%]">
              {/* <div className="absolute -right-5 float-right pr-[1rem] pt-[1rem]">
                <img src={blueStar} alt="" />
              </div> */}
              <div className="xs:w-fit xs:h-fit">
                <img src={progranStart} alt="" />
              </div>
            </div>

            {/* <div className="flex justify-end gap-2 py-[2rem] items-center text-[#ecbc2c]">
              <p>Register</p> <img src={yellowRightArrow} alt="" />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurEvent;
