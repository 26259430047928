const LearnServed = () => {
  return (
    <>
      <div className="flex xs:block sm:block md:block justify-between max-w-[75.688rem] mx-auto">
        <div className="flex max-w-[35rem] sm:mx-auto md:mx-auto  xs:gap-[.0rem] gap-[1.5rem] justify-between">
          <div>
            <h1 className="text-[48px] xs:text-[30px]  sm:text-[30px] md:text-[30px] font-[800] lg:text-[30px]">
              1000+
            </h1>
            <h1 className="text-[24px] xs:text-[15px] sm:text-[15px] lg:text-[15px]">
              Learners served
            </h1>
          </div>

          <div>
            <h1 className="text-[48px] xs:text-[30px] sm:text-[30px] md:text-[30px] font-[800] lg:text-[30px]">
              8+
            </h1>
            <h1 className="text-[24px] xs:text-[15px] sm:text-[15px] lg:text-[15px]">
              Courses
            </h1>
          </div>
          <div>
            <h1 className="text-[48px] xs:text-[30px] sm:text-[30px] md:text-[30px] font-[800] lg:text-[30px]">
              4+
            </h1>
            <h1 className="text-[24px] xs:text-[15px] sm:text-[15px] lg:text-[15px]">
              Countries
            </h1>
          </div>
          <div>
            <h1 className="text-[48px] xs:text-[30px] sm:text-[30px] md:text-[30px] font-[800] lg:text-[30px]">
              10+
            </h1>
            <h1 className="text-[24px] xs:text-[15px] sm:text-[15px] lg:text-[15px]">
              Sec. Schools
            </h1>
          </div>
        </div>
        <div>
          <p className="max-w-[27.313rem] xs:py-[1rem] sm:py-[1rem] xs:text-center sm:text-center xs:text-[13px] md:text-center sm:mx-auto md:mx-auto md:mt-[1rem]">
            Zulfah is where aspirations take flight and dreams come to life.
            Through our thoughtfully designed courses and programs, we meet you
            where you are and empower you to achieve the career you’ve always
            envisioned.
          </p>
        </div>
      </div>
    </>
  );
};

export default LearnServed;
