import FaqDropDown from "./faq-drop-down";

const Faq = () => {
  return (
    <>
      <div id="faq" className="my-[3rem]">
        <div className="max-w-[76.727rem] mx-auto font-[900]">
          <h1 className="text-[52.61px] ruda ">FAQ</h1>

          {/* <FaqDropDown
            h1Text={"Zulfah Academy ?"}
            description={
              'We are an Innovative Software company 👍  . Dont just take that as we have said it. check what we do here. The Academy is our "secret sauce." as a software company. The Bootcamp is a 6-months immersive training program that gives you the tools and training you need to launch a new career. The program trains people on-campus and online on fullstack engineering.'
            }
          /> */}
          <FaqDropDown
            h1Text={"Who can Apply ?"}
            description={
              "Anyone who is motivated, committed, has the zeal to learn, and is willing to commit full-time to the programme"
            }
          />
          <FaqDropDown
            h1Text={"Is your training physical or online?"}
            description={
              " We offer both physical, hybrid and remote options. Learners can either choose to learn in our physical location or take classes from anywhere via our remote channels."
            }
          />
            <FaqDropDown
            h1Text={"How much does the program cost?"}
            description={
              "At the moment,different programs have different costs ranging from #350,000 - #1,500,000. Please check the program page for the cost of the program you are interested in."
            }
          />
          
          <FaqDropDown
          h1Text={"Is it difficult to learn to code"}
          description={
            "No, coding is not difficult to learn. However, like with everything new, it may be challenging at first. The difficulty of learning to code will vary depending on a variety of factors. The idea is that learning to code isn't impossible, or at least not as difficult as it may appear, as long as you're dedicated."
          }
        />
        <FaqDropDown
          h1Text={"Do I need to be good in Math to learn to code"}
          description={
            "Math is great, but it is not an essential skill for the modern-day programmer. The modern-day programmer needs to be good at one or two programming languages and be proficient with data structures, design patterns, and databases. This is the typical knowledge stack they need to be a good programmer."
          }
          />
        <FaqDropDown
          h1Text={"How long does it take to learn to code"}
          description={
            "It can take anywhere from 3 months to 6 months to learn to code. This time will vary depending on your dedication, your natural aptitude for coding, the amount of time you spend learning, and the complexity of the coding language you are learning."
          }
         />
         <FaqDropDown
          h1Text={"What is the average salary of a coder"}
          description={
            "The global average for an entry-level coder is $53,000, but you can earn an average of up to $80,000 a year, depending on your location. The average salary drastically increases with more experience and a higher job title."
          }
         />
           <FaqDropDown
            h1Text={"Can we partner with Zulfah"}
            description={
              "Yes, you can. Kindly reach out to us via info@zulfahgroup.com or +2348115371014"
            }
          />
            <FaqDropDown
            h1Text={"Who can we contact for more information?"}
            description={
              "Please reach out to info@zulfahgroup.com for any further questions or +2348115371014"
            }
          />
        </div>
      </div>
    </>
  );
};

export default Faq;

{
  /* <div className="rounded-[14.61px] border my-[1rem] border-[#f8f8f8]">
  <div className="bg-[#f8f8f8]  flex items-center justify-between rounded-tr-[14.61px] px-[2rem] rounded-tl-[14.61px]">
    <h1 className="text-[30px] text-[#59b5cd] font-[800] py-[1rem] ">
      What is Zulfah ?
    </h1>
    <div>
      <img src={plusInBlueCirclle} alt="" />
    </div>
  </div>
  <div>
    <p className="max-w-[72.813rem] text-[18px] font-[400] mx-auto py-[.5rem]">
      We are an Innovative Software company 👍  . Dont just take that
      as we have said it. check what we do here. The Academy is our
      "secret sauce." as a software company. The Bootcamp is a
      6-months immersive training program that gives you the tools and
      training you need to launch a new career. The program trains
      people on-campus and online on fullstack engineering.
    </p>
  </div>
</div>
<div className="rounded-[14.61px] border my-[1rem] border-[#f8f8f8]">
  <div className="bg-[#f8f8f8]  flex items-center justify-between rounded-tr-[14.61px] px-[2rem] rounded-tl-[14.61px]">
    <h1 className="text-[30px] text-[#59b5cd] font-[800] py-[1rem] ">
      Who Can Apply ?
    </h1>
    <div>
      <img src={plusInBlueCirclle} alt="" />
    </div>
  </div>
  <div>
    <p className="max-w-[72.813rem] text-[18px] font-[400] mx-auto py-[.5rem]">
      Anyone who is motivated, committed, has the zeal to learn, and
      is willing to commit full-time to the programme
    </p>
  </div>
</div>
<div className="rounded-[14.61px] border my-[1rem] border-[#f8f8f8]">
  <div className="bg-[#f8f8f8]  flex items-center justify-between rounded-tr-[14.61px] px-[2rem] rounded-tl-[14.61px]">
    <h1 className="text-[30px] text-[#59b5cd] font-[800] py-[1rem] ">
      Is the training on-campus or remote?
    </h1>
    <div>
      <img src={plusInBlueCirclle} alt="" />
    </div>
  </div>
  <div>
    <p className="max-w-[72.813rem] text-[18px] font-[400] mx-auto py-[.5rem]">
      We offer both physical and remote options. Learners can either
      choose to learn in our physical location or take classes from
      anywhere via our remote channels.
    </p>
  </div>
</div> */
}
