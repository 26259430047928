import { callIcon, mailIcon } from "../../assets";

const SecondCard = () => {
  return (
    <>
      <div className="max-w-[40.188rem]  mt-[3rem] my-[2rem] py-[3rem] px-[1.5rem] mx-auto text-white bg-[#4894a9] rounded-[9.06px]">
        <h1 className="text-[24px] font-[700]">Have Any Questions</h1>
        <p className="text-[20px] font-[400] my-[1.5rem]">
          Feel free to reach out if you have any questions—I'm here to help!
        </p>
        <div className="text-[20px] ">
          <div className="flex gap-2">
            <img src={callIcon} alt="" />
            <h1 className="text-[14.85px]">
              <a href="tel:09031693655">09031693655</a>, &nbsp;
              <a href="tel:08084259372">08084259372 </a>, &nbsp;  
              <a href="tel:08115371014">08115371014</a>
            </h1>
          </div>
          <div className="flex gap-2 mt-[1rem]">
            <img src={mailIcon} alt="" />
            <a href="mailto:info@zulfahgroup.com" className="text-[15.19px]">
              info@zulfahgroup.com
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondCard;
