import { courseDescription, curriculumOverview, keySkill } from "../../../assets";
import Button from "../../../components/button/button";
import { Link } from "react-router-dom";

const CourseDescription = () =>{
    return (
      <>
        <div className="mt-[4rem] xs:text-center sm:text-center md:text-center lg:text-center xs:mx-auto">
          <div className="">
            {/* <img src={courseDescription} alt="" /> */}
            <h1 className="text-[24px] font-[900] ruda">
              Course Description: Data Science Bootcamp
            </h1>
          </div>

          <div className="max-w-[44.375rem]   text-[20px]">
            <h1 className=" font-[700] mt-[2rem]">
              What Does a Data Scientist Do?
            </h1>

            <p className="font-[300]">
              Data scientists turn raw data into actionable insights. They
              design and implement algorithms, build predictive models, and
              communicate findings that drive decision-making. Working at the
              intersection of statistics, programming, and domain expertise,
              data scientists use cutting-edge tools and techniques to uncover
              trends, automate processes, and solve complex problems. As a data
              scientist, you will:
            </p>
          </div>

          <ul className="max-w-[45.625rem] my-[2rem] xs:text-[15px] sm:text-[18px] text-[20px] font-[300] pl-[2.2rem] list-disc">
            <li>
              Analyze and preprocess large datasets to ensure data quality.{" "}
            </li>
            <li>
              Build predictive and descriptive models using machine learning
              algorithms.
            </li>
            <li>
              Visualize data to communicate insights effectively to
              stakeholders.
            </li>
            <li>
              Deploy machine learning models into production environments.
            </li>
            <li>
              Use statistical techniques to validate hypotheses and guide
              business decisions.
            </li>
            <li>Work with big data technologies like Hadoop and Spark.</li>
            <li>
              Collaborate with cross-functional teams to solve complex problems.
            </li>
          </ul>

          <div className="max-w-[44.5rem] my-[1rem] xs:text-[15px] sm:text-[18px] text-[20px]">
            <h1 className="font-[700]">
              What Will You Learn in our Data science Bootcamp?
            </h1>
            <p className="font-[300]">
              Our data science bootcamp provides hands-on experience with the
              entire data science pipeline, from data collection to model
              deployment. You’ll gain proficiency in tools like Python, R, SQL,
              and cloud-based platforms, while mastering techniques in machine
              learning, data visualization, and big data processing.
            </p>
          </div>

          <div className="">
            <h1 className="text-[24px] xs:text-[17px] sm:text-[19px] font-[900] ruda my-[1.5rem]">
              Curriculum Overview
            </h1>
          </div>
          <div className="max-w-[35.125rem] text-[20px] xs:text-[15px] sm:text-[18px] xs:mx-auto sm:mx-auto md:mx-auto">
            <div>
              <h1 className="font-[600]">Data Exploration & Analysis:</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Data Cleaning, Transformation, and Preprocessing</li>
                <li>
                  Exploratory Data Analysis (EDA) using Pandas, NumPy, and
                  Matplotlib
                </li>
                <li>Statistical Analysis and Hypothesis Testing </li>
              </ul>
            </div>

            <div>
              <h1 className="font-[600]">Programming for Data Science:</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Python Programming for Data Analysis</li>
                <li>R for Statistical Computing</li>
                <li>SQL for Data Retrieval and Management</li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">Machine Learning & AI:</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Supervised Learning (Regression, Classification)</li>
                <li>
                  Unsupervised Learning (Clustering, Dimensionality Reduction){" "}
                </li>
                <li>Neural Networks and Deep Learning Basics</li>
                <li>Model Evaluation and Hyperparameter Tuning</li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">Data Visualization:</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  Creating Dashboards and Reports with Tableau and Power BI
                </li>
                <li>Advanced Data Visualization in Python (Seaborn, Plotly)</li>
                <li>Storytelling with Data for Non-Technical Audiences</li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">Big Data & Cloud Computing:</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  Introduction to Big Data Frameworks (Hadoop, Apache Spark)
                </li>
                <li>Distributed Computing and Data Pipeline Design</li>
                <li>
                  Cloud-Based Machine Learning with AWS, Azure, and Google Cloud
                </li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">Deployment & Production:</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Model Deployment with Flask, Django, and FastAPI </li>
                <li>Monitoring and Updating Models in Production</li>
                <li>Building End-to-End Data Science Pipelines</li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">Specialized Topics:</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Natural Language Processing (NLP) </li>
                <li>Time Series Analysis and Forecasting </li>
                <li>Data Ethics and Responsible AI</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="xs:text-center sm:text-center md:text-center lg:text-center xs:mx-auto">
          <h1 className="text-[24px] xs:text-[17px] sm:text-[19px] font-[900] ruda my-[1.5rem]">
            Key Skills You Will Develop
          </h1>
        </div>
        <div className="text-[20px] xs:text-[15px] sm:text-[18px] font-[300]">
          <ul className="list-disc  pl-[2.2rem] ">
            <li>Soft Skills:</li>
            <li>
              Critical Thinking and Problem Solving, Communication of Technical
              Concepts, Collaboration in Cross-Functional Teams
            </li>
            <li>Hard Skills:</li>
            Python and R Programming, Data Cleaning, Analysis, and
            Visualization, Machine Learning Model Development and Deployment,
            Working with Big Data and Cloud Platforms, Building and Automating
            Data Science Pipelines
          </ul>
          <p className="py-[1rem] xs:text-center sm:text-center md:text-center lg:text-center xs:mx-auto">
            Upon completing our Software Engineering Bootcamp, you will be Upon
            completing our Data Science Bootcamp, you will have the skills to
            tackle real-world challenges and unlock new opportunities in
            industries ranging from healthcare to finance, tech, and beyond.
          </p>
        </div>
        <div className="max-w-[44.5rem] my-[1rem] xs:text-[15px] sm:text-[18px] text-[20px]">
          <h1 className="font-[700]">Cost of the Course:</h1>
          <br />
          <p className="font-[300]">
            <ul className="list-disc font-[300] pl-[2.2rem]">
              <li>
                Data Science : <span className="font-bold">#500,000</span>
              </li>
            </ul>
          </p>
        </div>
        <Link to="/get-started">
          <Button
            btnText={"Join Now"}
            btnClassName={
              "!text-[18px] !h-fit !w-fit px-4 xs:text-[13px] sm:text-[16px] !bg-[#ecb32c] mt-[2rem]"
            }
          />
        </Link>
        <br />
      </>
    );
};

export default CourseDescription;