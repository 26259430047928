import { whatsapp } from "../../assets";

const WhatsAppButton = ({ phoneNumber, message, className }) => {
  const handleClick = () => {
    const url = `https://wa.me/+2348115371014?text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };

  return (
    <div
      className={` flex gap-2 py-2 rounded-full px-5 items-center bg-[#25D366] ${className}`}
      onClick={handleClick}
      //   style={styles.button}
    >
      <div className=" xs:hidden sm:hidden  bg-opacity-45 px-3 py-1 font-[600] bg-[] rounded-md  ">
        <p className="text-white ">Chat with us on whatsApp</p>
      </div>
      <div>
        <div className="absolute rounded-full animate-ping -z-30 bottom-[.5rem] sm:hidden xs:hidden right-[3.7rem]  w-[10rem] h-[2rem] bg-[#25D366]"></div>
        <img
          src={whatsapp}
          className="text-white z-50  cursor-pointer  "
          alt=""
        />
      </div>
    </div>
  );
};





export default WhatsAppButton;
