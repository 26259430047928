import { monitorInYellowCircle, whyZulfahImg } from "../../../assets";
import whyZulfah from "../../../utils/static-datas/why-zulfah";

const WhyZulfah = () => {
  return (
    <>
      <div className="flex xs:block sm:block md:block justify-between max-w-[77.044rem] mx-auto my-[1rem] ">
        <div className="">
          <div className="xs:text-center md:text-center sm:text-center">
            <h1 className="font-[900] pb-[1.5rem] ruda text-[24px]">
              Why Zulfah ?
            </h1>
            <p className="max-w-[31.438rem] md:mx-auto ">
              At Zulfah, we are committed to providing outstandning tech
              education that transforms dreams into realities. Our mission is to
              equip individuals with the knowledge and abilities required to
              succeed in the ever-changing technology industry
            </p>
          </div>
          <div className=" ">
            {whyZulfah.map((each, i) => {
              return (
                <div
                  className="bg-[#f8f8f8] flex max-w-[34.5rem] md:mx-auto  rounded-[20px] px-[1rem] py-[1rem] gap-[1rem] mt-[2rem]"
                  key={i}
                >
                  <div className="md:mx-auto">
                    <img src={each.whyZulIcon} alt="" />
                  </div>
                  <div>
                    <h1 className="text-[20px] ruda font-[700]">
                      {each.heading}
                    </h1>
                    <p className="max-w-[27.5rem] text-[17px] leading-[28.9px]">
                      {each.details}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="md:mt-[1rem] sm:mt-[1rem] xs:mt-[1rem]">
          <img src={whyZulfahImg} alt="" />
        </div>
      </div>
    </>
  );
};

export default WhyZulfah;
