import { alTanZeel, heritageCollage, landmarkCollage } from "../../../assets";
import Button from "../../../components/button/button";
import MonthlySuperstarCard from "./month-superstar-card";

const MonthSuperstar = () => {
  return (
    <>
      <div className="max-w-[75.75rem] mx-auto my-[6rem]">
        <div className="flex  mx-auto gap-1 justify-between items-end">
          <h1 className="max-w-[19.563rem] ruda text-[32px] xs:text-[12px] sm:text-[14px] md:text-[16px] sm:block md:block font-[900] ">
           Partner School
          </h1>
          <div className="max-w-[40.625rem] border-black basis-[60%] my-[1rem] border-b-[1px]"></div>

          {/* <Button
            btnText={"Explore Programs"}
            btnClassName={
              "px-[1rem] xs:text-[10px] sm:text-[10px] md:text-[15px] lg:text-[15px] !h-fit !w-fit"
            }
          /> */}
        </div>
        <div className="flex md:w-full xs:w-full sm:w-full lg:w-full  xs:block sm:block md:block xs:space-y-4 sm:space-y-4 md:space-y-4 justify-between my-[1.5rem]">
          {/* <MonthlySuperstarCard
            img={landmarkCollage}
           
          />

          <MonthlySuperstarCard
            img={heritageCollage}
            
          /> */}
          {/* <MonthlySuperstarCard
            img={alTanZeel}
           
          />
          <MonthlySuperstarCard
            img={alTanZeel}
           
          />
          <MonthlySuperstarCard
            img={alTanZeel}
           
          /> */}
        </div>
      </div>
    </>
  );
};

export default MonthSuperstar;
