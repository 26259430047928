import { Link, NavLink } from "react-router-dom";
import {
  arrowDown,
  closeIcon,
  hamburger,
  zulfahLogoAndText,
} from "../../../assets";
import Button from "../../button/button";
import {
  navCoursesDropDown,
  navLinkDatas,
  navResourceDropDown,
} from "../../../helpers/nav-link-data";
import zulfahLogo from "../../../assets/ZULFAH-17.png";
import { useState } from "react";
import "animate.css";

const Navbar = () => {
  const [showSideNav, setShowSideNav] = useState(false);

  const handleToggleSideNav = () => {
    setShowSideNav(!showSideNav);
  };
  const handleCloseNav = () => {
    setShowSideNav(false);
  };

  return (
    <>
      <div className="flex   items-center mx-auto max-w-[77.044rem] px-[1rem] my-[1.5rem] justify-between  rounded-[10px] py-[.5rem] bg-[#ecb32c] bg-opacity-[12%] border border-[#ecb32c]">
        <NavLink to={"/"}>
          <img src={zulfahLogoAndText} alt="zulfah icon" />
        </NavLink>

        {/* <div className="flex  justify-center gap-[1.8rem] text-[18.81px] items-center">
            <NavLink to={"/about-us"} end>
              <h1>About Us</h1>
            </NavLink>
            <NavLink to={"/courses"}>
              <div className="flex items-end gap-[.2em] ">
                <h1 className="">Courses </h1> <img src={arrowDown} alt="" />
              </div>
            </NavLink>
            <NavLink to={"/z-teens"}>
              <h1>Z-Teens</h1>
            </NavLink>
            <NavLink to={"/resources"}>
              <div className="flex items-end gap-[.2em] ">
                <h1 className="">Resources </h1> <img src={arrowDown} alt="" />
              </div>
            </NavLink>
            <NavLink to={"/project"}>
              <h1>Projects</h1>
            </NavLink>
          </div> */}

        <div className="flex md:hidden xs:hidden sm:hidden lg:hidden  justify-center gap-[1.8rem] text-[18.81px] items-center lg:absolute lg:w-fit  lg:bg-[#dff8ff] lg:h-full  lg:pl-4 lg:pr-[3rem] lg:pt-[3rem] lg:top-[6rem] lg:left-3 md:absolute md:w-fit  md:bg-[#dff8ff] md:h-full  md:pl-4 md:pr-[3rem] md:pt-[3rem] md:top-[6rem] md:left-3  sm:absolute sm:w-fit  sm:bg-[#dff8ff] sm:h-full  sm:pl-4 sm:pr-[3rem] sm:pt-[3rem] sm:top-[6rem] sm:left-3  xs:absolute xs:w-fit  xs:bg-[#dff8ff] xs:h-full  xs:pl-4 xs:pr-[3rem] xs:pt-[3rem] xs:top-[6rem] xs:left-3">
          {navLinkDatas.map((navLinkData) => {
            return (
              <NavLink to={navLinkData.url} end={navLinkData.end}>
                {({ isActive }) => {
                  return (
                    <div
                      className={` ${
                        navLinkData && "flex items-center gap-[.2em]"
                      } ${
                        navLinkData.name === "Resources" && "relative group "
                      } ${navLinkData.name === "Courses" && "relative group "}`}
                    >
                      {navLinkData.name === "Resources" && (
                        <div className="absolute hidden group-hover:!block  bg-white px-[2rem] py-[.5rem] rounded-[.5rem] leading-[2.5rem] top-[1.8rem]">
                          {navResourceDropDown.map((each) => {
                            return (
                              <Link to={each.url}>
                                <h1 className="hover:text-[#ecb32c]  text-[1.1rem]">
                                  {each.name}
                                </h1>
                              </Link>
                            );
                          })}
                        </div>
                      )}
                      {navLinkData.name === "Courses" && (
                        <div className="absolute hidden group-hover:!block w-[250%] bg-white px-[2rem] py-[.5rem] leading-[2.5rem] -left-[3rem] text-center top-[1.8rem] rounded-[.5rem]">
                          {navCoursesDropDown.map((each) => {
                            return (
                              <Link to={each.url}>
                                <h1 className="hover:text-[#ecb32c] text-[1rem]">
                                  {each.name}
                                </h1>
                              </Link>
                            );
                          })}
                        </div>
                      )}
                      <h1
                        className={`lg:!my-[1rem] md:!my-[1rem] sm:!my-[1rem] xs:!my-[1rem] ${
                          isActive && "!text-[#ecb32c]"
                        }`}
                      >
                        {navLinkData.name}
                      </h1>
                      {navLinkData?.icon && (
                        <img
                          className=" lg:!my-[1rem] md:!my-[1rem] sm:!my-[1rem] xs:!my-[1rem]"
                          src={arrowDown}
                          alt=""
                        />
                      )}
                    </div>
                  );
                }}
              </NavLink>
            );
          })}
          <Link to="/get-started">
            <Button btnText={"Get Started"} />
          </Link>
          <div
            onClick={handleCloseNav}
            className=" xl:!hidden cursor-pointer absolute top-[1rem] right-[1rem] "
          >
            <img src={closeIcon} alt="" />
          </div>
        </div>

        {showSideNav && (
          <div
            className={`animate__animated animate__fadeInLeft flex xl:!hidden md:block xs:block sm:block lg:block  justify-center gap-[1.8rem] text-[18.81px] items-center lg:absolute lg:w-fit  lg:bg-[#dff8ff] lg:h-full  lg:pl-4 lg:pr-[3rem] lg:pt-[3rem] lg:top-[1.4rem] lg:left-4 md:absolute md:w-fit  md:bg-[#dff8ff] md:h-full  md:pl-4 md:pr-[3rem] md:pt-[3rem] md:top-[1.4rem] md:left-4  sm:absolute sm:w-fit  sm:bg-[#dff8ff] sm:h-full  sm:pl-4 sm:pr-[3rem] sm:pt-[3rem] sm:top-[1.4rem] sm:left-2  xs:absolute xs:w-fit  xs:bg-[#dff8ff] xs:h-full  xs:pl-4 xs:pr-[3rem] xs:pt-[3rem] xs:top-[1.4rem] xs:left-2 z-20`}
          >
            {navLinkDatas.map((navLinkData) => {
              return (
                <NavLink to={navLinkData.url} end={navLinkData.end}>
                  {({ isActive }) => {
                    return (
                      <div
                        className={` ${
                          navLinkData && "flex items-end gap-[.2em]"
                        } ${
                          navLinkData.name === "Resources" && "relative group "
                        } ${
                          navLinkData.name === "Courses" && "relative group "
                        }`}
                      >
                        {navLinkData.name === "Resources" && (
                          <div className="absolute hidden group-hover:!block  bg-white px-[2rem] py-[.5rem] rounded-[.5rem] leading-[2.5rem] top-[1.8rem]">
                            {navResourceDropDown.map((each) => {
                              return (
                                <Link to={each.url}>
                                  <h1 className="hover:text-[#ecb32c]   text-[1.1rem]">
                                    {each.name}
                                  </h1>
                                </Link>
                              );
                            })}
                          </div>
                        )}
                        {navLinkData.name === "Courses" && (
                          <div className="absolute hidden group-hover:!block z-10 w-[250%] bg-white px-[2rem] py-[.5rem] leading-[2.5rem] -left-[3rem] text-center top-[1.8rem] rounded-[.5rem]">
                            {navCoursesDropDown.map((each) => {
                              return (
                                <Link to={each.url}>
                                  <h1 className="hover:text-[#ecb32c]  text-[1rem]">
                                    {each.name}
                                  </h1>
                                </Link>
                              );
                            })}
                          </div>
                        )}
                        <h1
                          className={`lg:!my-[1rem] md:!my-[1rem] sm:!my-[1rem] xs:!my-[1rem] ${
                            isActive && "!text-[#ecb32c]"
                          }`}
                        >
                          {navLinkData.name}
                        </h1>
                        {navLinkData?.icon && (
                          <img
                            className="lg:!my-[1rem] md:!my-[1rem] sm:!my-[1rem] xs:!my-[1rem]"
                            src={arrowDown}
                            alt=""
                          />
                        )}
                      </div>
                    );
                  }}
                </NavLink>
              );
            })}
            <Link to="/get-started">
              <Button btnText={"Get Started"} />
            </Link>
            <div
              onClick={handleCloseNav}
              className={` xl:!hidden cursor-pointer absolute top-[1rem] right-[1rem] `}
            >
              <img src={closeIcon} alt="" />
            </div>
          </div>
        )}

        <div
          onClick={handleToggleSideNav}
          className=" cursor-pointer xl:!hidden"
        >
          <img src={hamburger} alt="" />
        </div>
      </div>
    </>
  );
};

export default Navbar;
