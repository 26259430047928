import { readyToJoin } from "../../../assets";
import Button from "../../../components/button/button";

const ReadyToJoin = () => {
  return (
    <>
      <div className="w-full  xs:block sm:block md:block  flex mx-auto bg-[#59b9cd]">
        <div className="bg-[#ecb32c] basis-[50%]">
          <div className=" gap-[6rem] ">
            <div className="bg-[#ecb32c] px-[4rem] py-[4rem] basis-[50%]">
              <div className="max-w-[30.625rem] xs:mx-auto sm:mx-auto md:mx-auto xs:text-center sm:text-center md:text-center ">
                <h1 className="text-[24px] font-[900] ruda">
                  Ready to Join the Z Teens Club?
                </h1>
                <p className="text-[20px] font-[400] my-[2rem]">
                  Become part of the tech movement in your school
                </p>
                <Button
                  btnText={"Join Z Teens Today"}
                  btnClassName={"!bg-[#cf375d] !w-fit !h-fit px-[1.5rem] mt-[2rem] xs:text-[12px] sm:text-[16px]"}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="bg-[#59b9cd] xs:hidden sm:hidden md:hidden  ">
            <img src={readyToJoin} alt="" className="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReadyToJoin;
