import { useNavigate } from "react-router-dom";
import {
  arrowDown,
  technologyIsArt,
  zulfahLogoAndText,
  zulfalFooterLogo,
} from "../../assets";
import Button from "../../components/button/button";
import Layout from "../../components/shared/layout/layout";
import DashboardVideo from "./component/dashboard-video";
import ExploreOur from "./component/expolre-our";
import Faq from "./component/faq";
import HowItWorks from "./component/how-it-works";
import LearnServed from "./component/learn-served";
import OurEvent from "./component/our-event";
import StayConnected from "./component/stay-connected";
import Testimonial from "./component/testimonial";
import WhereOurTalent from "./component/where-our-talent";
import WhyZulfah from "./component/why-zulfah";
import WhatsAppButton from "../../components/chat-us/whatsApp";
import techFit from "../../assets/techFit.jpeg"; 
import { Link } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();
  // const phoneNumber = process.env.REACT_APP_WHATSAPP_NUMBER;

  return (
    <>
      <Layout>
        {/* <WhatsAppButton
          phoneNumber={phoneNumber}
          className={"fixed right-5 bottom-7"}
          message={"Hello, I need more enquiries about Zulfah!"}
        /> */}

        <div className="mx-auto xs:text-[10px] py-[1rem] max-w-[74rem] rounded-[20px] text-center bg-[#dff8ff]">
          <h1>
            Uncertain about your tech career path? Discover your future at our
            innovative tech school. Enroll today! 🎉
          </h1>
        </div>

        <div className="max-w-[67.688rem] xs:text-[52px] xs:leading-[29.52px] text-[72px] mx-auto  mt-[1rem] text-center">
          <h1 className="leading-[88.56px] font-[900] ruda  ">
            Empower Your Future with World-Class{" "}
            <span className="text-[#59b5cd]">Tech</span> Education
          </h1>
          <div className="max-w-[45.75rem] py-[1rem] text-[24px] font-[400] text-center mx-auto xs:text-[12px]">
            Future-Ready Skills through Comprehensive, Hands-On Training Led by
            Industry Experts and Innovators
          </div>
          <div className="flex gap-10 justify-center">
            <Link to="/get-started">
              <Button
                btnClassName={
                  "text-[16.54px]  xs:w-fit !xs:h-fit !xs:py-0 xs:px-[1rem] xs:text-[12px] "
                }
                btnText={"Enrol Now"}
              />
            </Link>
            <Button
              onClick={() => {
                navigate("/courses");
              }}
              btnClassName={
                "text-[16.54px] xs:text-[12px] xs:w-fit !xs:py-0 !xs:h-fit xs:px-[1rem] !text-[#ecb32c] border !border-[#ecb32c] !bg-white"
              }
              btnText={"Explore Courses"}
            />
          </div>
        </div>
        <DashboardVideo />
        <WhyZulfah />
        <ExploreOur />
        <LearnServed />
        <HowItWorks />
        <div className="xs:pt-[4rem] sm:pt-[4rem] md:pt-[4rem]">
          <img src={technologyIsArt} alt="" className="w-full " />
        </div>
        <Testimonial />
        {/* <WhereOurTalent /> */}
        <OurEvent />
        <Faq />
        {/* <StayConnected /> */}
      </Layout>
    </>
  );
};

export default LandingPage;
