import { courseDescription, curriculumOverview, keySkill } from "../../../assets";
import Button from "../../../components/button/button";
import { Link } from "react-router-dom";

const CourseDescription = () =>{
    return (
      <>
        <div className="mt-[4rem] xs:text-center sm:text-center md:text-center lg:text-center xs:mx-auto">
          <div className="">
            {/* <img src={courseDescription} alt="" /> */}
            <h1 className="text-[24px] font-[900] ruda">Course Description</h1>
          </div>

          <div className="max-w-[44.375rem] text-[20px]">
            <h1 className=" font-[700] mt-[2rem]">
              What does a DevOps Engineer do?
            </h1>

            <p className="font-[300]">
              DevOps engineers bridge the gap between software development and
              IT operations. They implement practices and tools that enhance
              collaboration, streamline workflows, and ensure faster, more
              reliable software delivery. As a certified DevOps engineer, you
              will automate processes, build CI/CD pipelines, and optimize
              systems for scalability and resilience, driving innovation and
              operational efficiency.
            </p>
          </div>

          <div className="max-w-[44.5rem] xs:text-[15px] sm:text-[18px] my-[1rem] text-[20px]">
            <h1 className="font-[700]">
              What Will You Learn in a Coding Class?
            </h1>
            <p className="font-[300]">
              Our DevOps boot camp is a learner-focused program that combines
              hands-on, project-based training with industry-relevant tools and
              practices. You’ll master the skills to automate processes, manage
              infrastructure, and enable continuous delivery of high-quality
              applications — propelling your career to the next level.
            </p>
          </div>

          <div className="">
            {/* <img src={curriculumOverview} alt="" /> */}
            <h1 className="text-[24px]  xs:text-[17px] sm:text-[19px] font-[900] ruda my-[1.5rem]">
              Curriculum Overview
            </h1>
          </div>
          <div className="max-w-[35.125rem] text-[20px] xs:text-[15px] sm:text-[18px] xs:mx-auto sm:mx-auto md:mx-auto">
            <div>
              <h1 className="font-[600]">
                Module 1: Linux Fundamentals and Shell Scripting
              </h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Linux commands and administration </li>
                <li>File system navigation </li>
                <li>Shell scripting for automation </li>
                <li>User and process management</li>
              </ul>
            </div>

            <div>
              <h1 className="font-[600]">Module 2: Version Control with Git</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Git fundamentals </li>
                <li>Branching and merging </li>
                <li>Collaboration workflows </li>
                <li>GitHub and GitLab integrations</li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">Module 3: CI/CD Pipelines</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Jenkins setup and configuration</li>
                <li>GitLab CI/CD </li>
                <li>Automated testing and deployment </li>
                <li>Pipeline optimization</li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">
                Module 4: Containerization and Orchestration
              </h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Docker fundamentals </li>
                <li>Creating and managing containers </li>
                <li>Kubernetes orchestration </li>
                <li>Helm for managing Kubernetes applications</li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">
                Module 5: Cloud Infrastructure and Automation
              </h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>AWS, Azure, or GCP basics </li>
                <li>Infrastructure as Code (IaC) with Terraform </li>
                <li>Ansible for configuration management </li>
                <li>Monitoring and scaling</li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">Module 6: Monitoring and Logging</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>Prometheus and Grafana</li>
                <li>ELK Stack (Elasticsearch, Logstash, Kibana) </li>
                <li>Log aggregation and analysis </li>
                <li>Incident response</li>
              </ul>
            </div>

            <div>
              <h1 className="font-[600]">Module 7: Final Project</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  Apply all your skills in a real-world scenario by designing
                  and implementing a fully automated CI/CD pipeline for an
                  application. Collaborate with peers to deliver an impressive
                  portfolio project.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="flex gap-2 items-center">
          {/* <img src={keySkill} alt="" /> */}
          <h1 className="text-[24px] xs:text-[17px] sm:text-[19px] font-[900] ruda my-[1.5rem]">
            Skills to excel in DevOps
          </h1>
        </div>
        <div className="text-[20px] xs:text-[15px] sm:text-[18px] font-[300]">
          <ul className="list-disc  pl-[2.2rem] ">
            <li>Soft Skills:</li>
            <li>
              Adaptability, Business Acumen, Critical Thinking, Communication,
              Problem Solving, Organization, Attention to details
            </li>
            <li>Hard Skills:</li>
            <li>
              Linux administration, Docker and Kubernetes, Terraform and
              Ansible, Jenkins and GitLab CI, Monitoring with Prometheus and
              Grafana Scripting (Python, Bash)
            </li>
          </ul>
          <p className="py-[1rem] xs:text-center sm:text-center md:text-center lg:text-center xs:mx-auto">
            Our DevOps graduates are equipped to manage infrastructure,
            streamline software delivery, and support teams in achieving faster,
            higher-quality production deployments. Transform your skills,
            collaborate effectively, and drive innovation with DevOps!
          </p>
        </div>
        <div className="max-w-[44.5rem] my-[1rem] xs:text-[15px] sm:text-[18px] text-[20px]">
          <h1 className="font-[700]">Cost of the Course:</h1>
          <br />
          <p className="font-[300]">
            <ul className="list-disc font-[300] pl-[2.2rem]">
              <li>
                DevOps : <span className="font-bold">#500,000</span>
              </li>
            </ul>
          </p>
        </div>
        <Link to="/get-started">
          <Button
            btnText={"Join Now"}
            btnClassName={
              "!text-[18px] xs:text-[14px] sm:text-[16px] !bg-[#ecb32c] mt-[2rem]"
            }
          />
        </Link>
        <br />
      </>
    );
};

export default CourseDescription;