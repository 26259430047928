import { useState } from "react";
import { plusInBlueCirclle } from "../../../assets";

const FaqDropDown = ({ h1Text, description }) => {
    const [showFaq, setshowFaq] = useState(false);

  return (
    <>
      <div className="rounded-[14.61px] border my-[1rem] border-[#f8f8f8]">
        <div className="bg-[#f8f8f8]  flex items-center justify-between rounded-tr-[14.61px] px-[2rem] xs:px-[1rem] rounded-tl-[14.61px]">
          <h1 className="text-[20px] text-[#59b5cd] xs:basis-[80%] xs:text-[18.45px] font-[800] py-[1rem] ">
            {h1Text}
          </h1>
          <div className="cursor-pointer" onClick={()=>{
            setshowFaq(!showFaq)
          }}>
            <img src={plusInBlueCirclle} alt="" />
          </div>
        </div>
       {showFaq && <div>
          <p className="max-w-[72.813rem] xs:text-[11.1px] text-[18px] font-[400] mx-auto py-[.5rem]">
            {description}
          </p>
        </div>}
      </div>
    </>
  );
};

export default FaqDropDown;
